import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import _ from 'lodash'
import { Dropdown, EditDropdown, Icon, Pagination } from '@components'
import AppActions from '~/actions/app'
import { normalizePhoneInput, otherStatus } from '~/services/utils'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'

const _options = [
  { _id: 1, name: 'Name', value: 'fullName' },
  { _id: 2, name: 'Booking Time', value: 'bookingTime' },
  { _id: 3, name: 'Arrival Time', value: 'arrivalTime' },
  { _id: 4, name: 'Booking Status', value: 'status' },
]

const ReservationTab = ({ data, pagination, onDetail, onPagination, onPer, onMenu }) => (
  <>
    {data?.map((item) => {
      const { status, sentAcceptedSms, sentReadySms, sentRejectedSms } = item
      let option = ['Delete', 'View', 'Edit']
      if (status === 1) option = ['Delete', 'View', 'Accept Booking', 'Reject Booking', 'Edit']
      if (status === 2 && !sentAcceptedSms) option = ['Delete', 'View', 'Accept Booking Sms', 'Edit']
      if (status === 3 && !sentRejectedSms) option = ['Delete', 'View', 'Reject Booking Sms', 'Edit']
      if (sentAcceptedSms && !sentReadySms && status === 2) option = ['Delete', 'View', 'Booking Ready', 'Edit']

      return (
        <div
          className="d-flex align-items-start d-h-between py-3 bg-slight-grey border-5 px-2 m-2"
          key={item._id}
          onClick={onDetail(item)}
        >
          <div className="d-flex flex-column">
            <div className="d-flex-1">
              <span className="dsl-b20 text-600 main-blue">{item?.fullName}</span>
            </div>
            <br></br>
            <div className="d-flex-1">
              <Icon name="fal fa-users mr-1 color-basic" color="#343f4b" size={16} />
              <span className="dsl-b20 text-500 bold"> {item?.attendees}</span>
            </div>
            <div className="d-flex-1">
              <span className="dsl-b20 text-500">
                Arrival Time: {moment(new Date(item?.arrivalTime)).utc(false).format(' MMM Do, h:mm a')}
              </span>
            </div>
            <br></br>
            <div className="d-flex-1">
              <Icon name="fal fa-chair mr-1 color-basic" color="#343f4b" size={16} />
              <span className="dsl-b20 text-500">
                {item?.isOutdoorReservation ? 'Outdoor Sitting' : 'Indoor Sitting'}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <span
              className={classNames(
                'dsl-b20 text-500',
                otherStatus(item?.status) === 'Accepted' ? 'color-basic' : 'color-red '
              )}
            >
              {otherStatus(item?.status)}
            </span>
            <EditDropdown options={option} onChange={(e) => onMenu(e, item)} />
          </div>
        </div>
      )
    })}
    <div className="mt-2">
      <Pagination
        current={pagination?.currentPage}
        total={pagination?.totalPage}
        per={pagination?.limit}
        pers={[10, 20, 50, 'all']}
        onPer={onPer}
        onChange={onPagination}
      />
    </div>
  </>
)

class ReservationList extends Component {
  state = {
    search: '',
    page: 1,
    itemsPerPage: 10,
    sortBy: 'arrivalTime',
    date: null,
  }
  componentDidMount() {
    this.props.getBooking({ page: 1, itemsPerPage: 10, sortBy: ['arrivalTime'], sortDesc: ['false'] })
  }

  handleAdd = () => {
    this.props.toggleModal({ type: 'Add New Reservation' })
  }

  handleDetail = (item) => () => {
    const route = `/home/reservation/${item._id}`
    this.props.getBookingDetail(item._id, route)
  }

  handleMenu = (e, item) => {
    if (e === 'delete') {
      this.props.deleteReservation('reservation', item._id)
    }
    if (e === 'view') {
      const route = `/home/reservation/${item._id}`
      this.props.getBookingDetail(item._id, route)
    }
    if (e === 'accept booking sms') {
      this.handleSms(item._id, 'reservationAcceptedSms')
    }
    if (e === 'reject booking sms') {
      this.handleSms(item._id, 'reservationRejectedSms')
    }
    if (e === 'booking ready') {
      this.handleSms(item._id, 'reservationReadySms')
    }
    if (e === 'accept booking') {
      this.hanldeStatus(item, 'accept')
    }
    if (e === 'reject booking') {
      this.hanldeStatus(item, 'reject')
    }
    if (e === 'edit') {
      this.props.toggleModal({
        type: 'Edit Reservation',
        data: { before: { data: item } },
        callBack: this.handleFetch,
      })
    }
  }

  handlePagination = (page) => {
    const { sortBy, itemsPerPage } = this.state
    this.props.getBooking({ page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ page })
  }

  handlePer = (itemsPerPage) => {
    const { sortBy } = this.state
    this.props.getBooking({ itemsPerPage, page: 1, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ itemsPerPage })
  }

  debouncedLog = _.debounce((text) => {
    const { sortBy, itemsPerPage } = this.state
    this.props.getBooking({ page: 1, search: text, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
  }, 500)

  handleSearch = (e) => {
    this.setState({ search: e })
    this.debouncedLog(e)
  }

  handleSort = (e) => {
    const { page, itemsPerPage } = this.state
    const sortBy = e[0]?.value
    this.props.getBooking({ page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
  }

  handleDateChange = (date) => {
    const { sortBy, itemsPerPage, page } = this.state
    const filter = date ? moment(date).format('YYYY-MM-DD') : null
    this.props.getBooking({ page, itemsPerPage, filter, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ date })
  }

  handleFetch = () => {
    const { sortBy, itemsPerPage, page, date } = this.state
    this.props.getBooking({ page, itemsPerPage, filter: date, sortBy: [sortBy], sortDesc: ['false'] })
  }

  hanldeStatus = (booking, mode) => {
    let payload = {}
    if (mode === 'accept') {
      payload = {
        ...booking,
        status: 2,
      }
    }
    if (mode === 'reject') {
      payload = {
        ...booking,
        status: 3,
      }
    }
    this.props.updateStatus(payload, booking._id, this.handleFetch)
  }

  handleSms = (id, mode) => {
    if (mode === 'reservationRejectedSms') {
      this.props.sendsmsorderRequest(
        id,
        { slug: 'reservationRejectedSms', reason: null, time: null },
        'reservation',
        null,
        this.handleFetch
      )
    }
    if (mode === 'reservationAcceptedSms') {
      this.props.sendsmsorderRequest(
        id,
        { slug: 'reservationAcceptedSms', reason: null, time: null },
        'reservation',
        null,
        this.handleFetch
      )
    }
    if (mode === 'reservationReadySms') {
      this.props.sendsmsorderRequest(
        id,
        { slug: 'reservationReadySms', reason: null, time: null },
        'reservation',
        null,
        this.handleFetch
      )
    }
  }

  render() {
    const { search, date } = this.state
    const { bookings } = this.props
    const { data, pagination } = bookings
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push('/home/reservation')}>
                    Reservation
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Reservation List</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="d-h-between">
            <h4>Reservation List</h4>
            <div className="d-flex align-items-baseline">
              <div className="dataTables_filter">
                <label>
                  <input
                    value={search}
                    type="search"
                    className="form-control"
                    placeholder="Search ..."
                    onChange={(e) => this.handleSearch(e.target.value)}
                  ></input>
                </label>
              </div>
              <button type="button" className="btn btn-square btn-link" onClick={this.handleAdd}>
                Add New Reservation
              </button>
            </div>
          </div>
          <div className="d-h-end">
            <Dropdown
              className="form-control"
              title="Filter By"
              placeholder="Select Category"
              returnBy="data"
              defaultIds={[3]}
              data={_options}
              getValue={(e) => e.name}
              onChange={this.handleSort}
            />
            <DatePicker
              className="form-control"
              placeholderText="Select Date"
              isClearable
              selected={date}
              onChange={this.handleDateChange}
              fixedHeight
              timeIntervals={15}
            />
          </div>
          <div className="mt-0 mt-md-4">
            <ReservationTab
              data={data}
              pagination={pagination}
              onPagination={this.handlePagination}
              onPer={this.handlePer}
              onDetail={this.handleDetail}
              onMenu={this.handleMenu}
            />
          </div>
        </div>
      </div>
    )
  }
}

ReservationList.propTypes = {
  bookings: PropTypes.shape({
    data: PropTypes.array,
    pagination: PropTypes.object,
  }),
}

ReservationList.defaultProps = {
  bookings: {
    data: [],
    pagination: {},
  },
}

const mapStateToProps = (state) => ({
  bookings: state.app.bookings,
})

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (payload) => dispatch(AppActions.modalRequest(payload)),
  deleteReservation: (mode, id, tab) => dispatch(AppActions.deleteRequest(mode, id, tab)),
  getBooking: (e) => dispatch(AppActions.getbookinglistRequest(e)),
  getBookingDetail: (id, route) => dispatch(AppActions.getbookingdetailRequest(id, route)),
  sendsmsorderRequest: (id, payload, mode, after, callBack) =>
    dispatch(AppActions.sendsmsorderRequest(id, payload, mode, after, callBack)),
  updateStatus: (payload, id, callBack) => dispatch(AppActions.updatereservationRequest(payload, id, true, callBack)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationList)
