import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from '@components'
import AppActions from '~/actions/app'
import { filter } from 'ramda'
import { getSettings } from '~/services/utils'

const Settings = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.app.settings)
  const category = useSelector((state) => state.app.category)

  useEffect(() => {
    dispatch(AppActions.getsettingRequest())
    dispatch(AppActions.categoryRequest())
  }, [])

  const selected = filter((item) => item.isHidden, category?.data)
  const selectedIds = selected?.map((item) => item._id)

  const [order_online, setOrderOnline] = useState(data?.orderStatus?.status)
  const [booking, setBooking] = useState(data?.reservationStatus?.status)
  const [taxRateValue, setTaxrate] = useState(data?.taxRate?.taxRateValue)
  const [selectedCategory, setSelectedCategory] = useState(selected)
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(selectedIds?.length === 0 ? null : selectedIds)

  const handleSettings = (e, mode) => {
    const payload = {
      status: e.target.checked,
      slug: mode,
    }
    if (mode === 'orderStatus') {
      setOrderOnline(e.target.checked)
      dispatch(AppActions.postsettingRequest('onlineOrder', payload))
    }
    if (mode === 'reservationStatus') {
      setBooking(e.target.checked)
      dispatch(AppActions.postsettingRequest('onlineReservation', payload))
    }
  }

  const handleTaxRate = () => {
    dispatch(AppActions.postsettingRequest('changeTaxRate', { slug: 'taxRate', taxRateValue }))
  }

  const handleCategory = () => {
    const name = getSettings(selectedCategoryIds, category, 'All the Category is availabe to user')
    const payload = {
      status: true,
      categoryIds: selectedCategoryIds || [],
    }
    dispatch(AppActions.postcategorysettingRequest(name, payload))
  }

  const handleIds = (e) => {
    const ids = e?.map((item) => item._id)
    setSelectedCategoryIds(ids?.length === 0 ? null : ids)
    setSelectedCategory(e)
  }

  const taxButton = taxRateValue === data?.taxRate?.taxRateValue

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="material-icons">home</i> Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/home/settings">Settings</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card">
        <h4>Settings</h4>
        <div className="d-flex flex-column pt-2">
          <div className="d-flex ">
            <span className="d-flex-1 dsl-b16">Order Online</span>
            <div className="d-flex-1">
              <label className="ms-switch ">
                <input type="checkbox" checked={order_online} onChange={(e) => handleSettings(e, 'orderStatus')} />
                <span className="ms-switch-slider round"></span>
              </label>
            </div>
          </div>
          <div className="d-flex pt-2">
            <span className="d-flex-1 dsl-b16">Online Reservation</span>
            <div className="d-flex-1">
              <label className="ms-switch ">
                <input type="checkbox" checked={booking} onChange={(e) => handleSettings(e, 'reservationStatus')} />
                <span className="ms-switch-slider round"></span>
              </label>
            </div>
          </div>
          <div className="d-flex pt-2">
            <span className="d-flex-1 dsl-b16">Tax Value</span>
            <div className="d-flex-1">
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id="validationCustom18"
                  placeholder="Tax Value"
                  value={taxRateValue}
                  required
                  onChange={(e) => setTaxrate(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex-1 align-self-center d-h-end">
              <button
                disabled={taxButton}
                className="btn btn-primary d-block mt-0"
                type="submit"
                onClick={handleTaxRate}
              >
                Apply
              </button>
            </div>
          </div>
          <div className="d-flex pt-2">
            <div className="d-flex-1 align-self-center">
              <Dropdown
                multi
                className="dsl-b16"
                defaultIds={selectedCategoryIds}
                title="Hidden Category"
                placeholder="Select Category"
                returnBy="data"
                data={category?.data || []}
                getValue={(e) => e.title}
                onChange={handleIds}
              />
            </div>
            <div className="d-flex-1 align-self-center d-h-end">
              <button className="btn btn-primary d-block" type="submit" onClick={handleCategory}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
