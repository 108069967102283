import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditDropdown, Pagination, Icon } from '@components'
import AppActions from '~/actions/app'

function Category() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AppActions.categoryRequest())
  }, [])
  const category = useSelector((state) => state.app.category)

  const handleAddCategory = () => {
    dispatch(AppActions.modalRequest({ type: 'Add Category' }))
  }

  const handleDetailCategory = (data) => () => {
    dispatch(AppActions.modalRequest({ type: 'Edit Category', data: { before: { data: data, mode: 'detail' } } }))
  }

  const handleEdit = (e, item) => {
    dispatch(AppActions.modalRequest({ type: 'Edit Category', data: { before: { data: item, mode: 'edit' } } }))
  }

  const handlePagination = (page) => {
    dispatch(AppActions.categoryRequest({ page }))
  }

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="material-icons">home</i> Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a className="text-red">Category</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a>Category List</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-md-12">
          <div className="ms-panel ms-panel-fh card">
            <div className="d-h-between">
              <h6>Category</h6>
              <div className="d-flex align-items-baseline">
                <button type="button" className="btn btn-square btn-link" onClick={handleAddCategory}>
                  <Icon name="fal fa-plus" /> Add Category
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <div className="list-header">
                  <div className="d-flex-4">Title</div>
                  <div className="d-flex d-flex-1">Hidden in Menu</div>
                  <div className="d-flex d-flex-1">Priority</div>
                  <div className="d-flex d-flex-1" />
                </div>
                {category?.data?.map((item) => (
                  <div className="list-item" key={item._id} onClick={handleDetailCategory(item)}>
                    <div className="d-flex-4">{item?.title}</div>
                    <div className="d-flex-1">{item.isHidden ? 'Yes' : 'No'}</div>
                    <div className="d-flex-1">{item.categoryPriority}</div>
                    <div className="d-flex d-flex-1 d-h-end">
                      <EditDropdown options={['Edit']} onChange={(e) => handleEdit(e, item)} />
                    </div>
                  </div>
                ))}
                <Pagination
                  current={category?.pagination?.currentPage}
                  total={category?.pagination?.totalPage}
                  per={category?.pagination?.limit}
                  pers={[]}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category
