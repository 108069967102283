import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CheckBox, Icon } from '@components'
import AppActions from '~/actions/app'
import './SendSms.scss'

function SendSms({ id, slug, after, onClose }) {
  const dispatch = useDispatch()

  const [time, setTime] = useState(null)
  const [reason, setReason] = useState(null)

  const handleSend = () => {
    const payload = {
      slug,
      reason,
      time: null,
    }
    dispatch(AppActions.sendsmsorderRequest(id, payload, 'order', after))
    onClose()
  }
  return (
    <div className="sms-modal">
      <div className="modal-body d-flex align-items-center px-5">
        <div className="form-row">
          {slug === 'orderRejectedSms' && (
            <div className="col-md-12 mb-3">
              <label>Reason</label>
              <div className="input-group">
                <textarea
                  rows="5"
                  type="text"
                  className="form-control"
                  placeholder="Reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
          )}
          {/* {slug === 'orderAcceptedSms' && (
            <div className="col-md-12 mb-3">
              <label>What Time will the Order be Ready?</label>
              <div className="row d-flex">
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="15 Mins" checked={time === '15'} id={'15mins'} onChange={() => setTime('15')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="30 Mins" checked={time === '30'} id={'30mins'} onChange={() => setTime('30')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="45 Mins" checked={time === '45'} id={'45mins'} onChange={() => setTime('45')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="60 Mins" checked={time === '60'} id={'60mins'} onChange={() => setTime('60')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="75 Mins" checked={time === '75'} id={'75mins'} onChange={() => setTime('75')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="90 Mins" checked={time === '90'} id={'90mins'} onChange={() => setTime('90')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="105 Mins" checked={time === '105'} id={'105mins'} onChange={() => setTime('105')} />
                </div>
                <div className="col-md-3 input-group m-2">
                  <CheckBox title="120 Mins" checked={time === '120'} id={'120mins'} onChange={() => setTime('120')} />
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div className="d-center mb-4">
        <button className="btn btn-primary d-block" style={{ fontSize: '18px' }} type="submit" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  )
}

export default SendSms
