import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppActions from '~/actions/app'

class SliderImage extends Component {
  state = {
    title: '',
    images: '',
    image: '',
    isFeaturedImage: false,
    galleryPriority: 1,
  }

  handleDrop = (e) => {
    const file = e.target.files
    this.setState({ images: file[0] })
    if (file.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result })
      })
      reader.readAsDataURL(file[0])
    }
  }

  handleSubmit = () => {
    const { title, images, isFeaturedImage, galleryPriority } = this.state
    const formData = new FormData()
    formData.set('Content-Type', images.type)
    formData.append('images', images)
    formData.append('title', title)
    formData.append('galleryPriority', galleryPriority)
    formData.append('isFeaturedImage', isFeaturedImage)
    formData.append('folder', 'Mandala')

    this.props.createNewGallery(formData)
  }

  render() {
    const { images, image, title, isFeaturedImage, galleryPriority } = this.state
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push('/home/slider/list')}>
                    Gallery
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Add Gallery Image</a>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div className="ms-panel-header">
                <h6>Add Gallery Image</h6>
              </div>
              <div className="ms-panel-body">
                <form className="needs-validation clearfix">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>Title</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="Title"
                          value={title}
                          required
                          onChange={(e) => this.setState({ title: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Priority</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="galleryPriority"
                          value={galleryPriority}
                          required
                          onChange={(e) => this.setState({ galleryPriority: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Gallery Image</label>
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" accept="image/*" onChange={this.handleDrop} />
                        <label className="custom-file-label" placeholder="Upload Gallery Image">
                          {images.name}
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Product </h6>
                  </div>
                  {image && (
                    <div className="ms-panel-body">
                      <div id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img className="d-block w-100" src={image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="ms-panel-header new">
                    <p className="medium">Use in homepage slider?</p>
                    <div>
                      <label className="ms-switch">
                        <input
                          type="checkbox"
                          checked={isFeaturedImage}
                          onChange={(e) => this.setState({ isFeaturedImage: e.target.checked })}
                        />
                        <span className="ms-switch-slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="ms-panel-header new">
                    <button
                      className="btn btn-primary d-block"
                      style={{ fontSize: '18px' }}
                      type="submit"
                      onClick={this.handleSubmit}
                    >
                      Save and Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createNewGallery: (e) => dispatch(AppActions.createnewgalleryRequest(e)),
})

export default connect(null, mapDispatchToProps)(SliderImage)
