import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dropdown, EditDropdown, Icon, Pagination } from '@components'
import AppActions from '~/actions/app'
import { normalizePhoneInput, otherStatus } from '~/services/utils'
import moment from 'moment'

const _options = [
  { _id: 1, name: 'Name', value: 'fullName' },
  { _id: 2, name: 'Booking Time', value: 'bookingTime' },
  { _id: 3, name: 'Arrival Time', value: 'arrivalTime' },
]

const WaitingListTab = ({ data, pagination, onDetail, onPagination, onPer, onMenu }) => (
  <>
    {data?.map((item) => (
      <div
        className="d-flex align-items-start d-h-between py-3 bg-slight-grey border-5 px-2 m-2"
        key={item._id}
        onClick={onDetail(item)}
      >
        <div className="d-flex flex-column">
          <div className="d-flex-1">
            <span className="dsl-b20 text-600 main-blue">{item?.fullName}</span>
          </div>
          <div className="d-flex-1">
            <Icon name="fal fa-users mr-1 color-basic" color="#343f4b" size={16} />
            <span className="dsl-b20 text-500"> {item?.attendees}</span>
          </div>
          <div className="d-flex-1">
            <Icon name="fal fa-chair mr-1 color-basic" color="#343f4b" size={16} />
            <span className="dsl-b20 text-500">
              {item?.isOutdoorReservation ? 'Outdoor Sitting' : 'Indoor Sitting'}
            </span>
          </div>
          <div className="d-flex-1">
            <span className="dsl-b20 text-500">
              Arrival Time:
              {moment(new Date(item?.arrivalTime)).utc(false).format(' MMM Do, h:mm a')}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <EditDropdown options={['Edit', 'Delete']} onChange={(e) => onMenu(e, item)} />
        </div>
      </div>
    ))}
    <div className="mt-2">
      <Pagination
        current={pagination?.currentPage}
        total={pagination?.totalPage}
        per={pagination?.limit}
        pers={[10, 20, 50, 'all']}
        onPer={onPer}
        onChange={onPagination}
      />
    </div>
  </>
)

class WaitingList extends Component {
  state = {
    page: 1,
    search: '',
    itemsPerPage: 10,
    sortBy: 'bookingTime',
  }
  componentDidMount() {
    this.props.getWaitingList({ page: 1, type: 2, itemsPerPage: 10, sortBy: ['bookingTime'], sortDesc: ['false'] })
  }

  handleAdd = () => {
    this.props.toggleModal({ type: 'Add New Booking' })
  }

  handleDetail = (item) => () => {
    const route = `/home/waitingList/${item._id}`
    this.props.getWaitingListDetail(item._id, route)
  }

  handleFetch = () => {
    const { sortBy, itemsPerPage, page } = this.state
    this.props.getWaitingList({ page, itemsPerPage, type: 2, sortBy: [sortBy], sortDesc: ['false'] })
  }

  handleMenu = (e, item) => {
    if (e === 'delete') {
      this.props.deleteWaitingList('booking', item._id)
    }
    if (e === 'edit') {
      this.props.toggleModal({
        type: 'Edit Booking',
        data: { before: { data: item } },
        callBack: this.handleFetch,
      })
    }
  }

  handlePagination = (page) => {
    const { itemsPerPage, sortBy } = this.state
    this.props.getWaitingList({ page, type: 2, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ page })
  }

  handlePer = (itemsPerPage) => {
    const { sortBy } = this.state
    this.props.getWaitingList({ itemsPerPage, page: 1, type: 2, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ itemsPerPage })
  }

  debouncedLog = _.debounce((text) => {
    const { itemsPerPage, sortBy } = this.state
    this.props.getWaitingList({ page: 1, type: 2, search: text, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
  }, 500)

  handleSearch = (e) => {
    this.setState({ search: e })
    this.debouncedLog(e)
  }

  handleSort = (e) => {
    const { page, itemsPerPage } = this.state
    const sortBy = e[0]?.value
    this.props.getWaitingList({ page, type: 2, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
    this.setState({ sortBy })
  }

  render() {
    const { search } = this.state
    const { reservations } = this.props
    const { data, pagination } = reservations
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push('/home/waitingList')}>
                    Waiting
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Waiting List</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="d-h-between">
            <h4>Waiting List</h4>
            <div className="d-flex align-items-baseline">
              <div className="dataTables_filter">
                <label>
                  <input
                    value={search}
                    type="search"
                    className="form-control"
                    placeholder="Search ..."
                    onChange={(e) => this.handleSearch(e.target.value)}
                  ></input>
                </label>
              </div>
              <button type="button" className="btn btn-square btn-link" onClick={this.handleAdd}>
                Add New Booking
              </button>
            </div>
          </div>
          <div className="d-h-end">
            <Dropdown
              className="form-control"
              title="Filter By"
              placeholder="Select Category"
              returnBy="data"
              defaultIds={[2]}
              data={_options}
              getValue={(e) => e.name}
              onChange={this.handleSort}
            />
          </div>
          <div className="mt-0 mt-md-4">
            <WaitingListTab
              data={data}
              pagination={pagination}
              onPagination={this.handlePagination}
              onPer={this.handlePer}
              onDetail={this.handleDetail}
              onMenu={this.handleMenu}
            />
          </div>
        </div>
      </div>
    )
  }
}

WaitingList.propTypes = {
  reservations: PropTypes.shape({
    data: PropTypes.array,
    pagination: PropTypes.object,
  }),
}

WaitingList.defaultProps = {
  reservations: {
    data: [],
    pagination: {},
  },
}

const mapStateToProps = (state) => ({
  reservations: state.app.reservations,
})

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (payload) => dispatch(AppActions.modalRequest(payload)),
  deleteWaitingList: (mode, id, tab) => dispatch(AppActions.deleteRequest(mode, id, tab)),
  getWaitingList: (e) => dispatch(AppActions.getwaitinglistRequest(e)),
  getWaitingListDetail: (id, route) => dispatch(AppActions.getwaitinglistdetailRequest(id, route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WaitingList)
