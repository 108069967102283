import React, { useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import dashboardRoutes from '../../routes/dashboard'
import { Header } from '@components'
import './Dashboard.scss'
import { onMessageListener } from '~/firebase'

const Content = ({ location, history, loggedIn, fixed, onToggle }) => {
  onMessageListener()
    .then((payload) => {
      onToggle({
        type: 'Notification',
        data: { before: { data: payload } },
      })
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <div>
      <div className="body-content">
        {loggedIn && <Header location={location} history={history} />}
        <Switch>
          {dashboardRoutes.map((route, key) => {
            if (route.redirect && !route.auth) return <Redirect exact from={route.path} to={route.to} key={key} />
            return <Route path={route.path} component={route.component} key={key} />
          })}
        </Switch>
      </div>
    </div>
  )
}

export default Content
