import React from 'react'
import { Document, Font, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import robotoNormal from '~/assets/fonts/Roboto-Regular.ttf'
import robotoMedium from '~/assets/fonts/Roboto-Medium.ttf'
import robotoBold from '~/assets/fonts/Roboto-Bold.ttf'
import { normalizePhoneInput, orderStatus, paymentStatus, spiceLevelStatus } from '~/services/utils'

const styles = StyleSheet.create({
  headerColumn: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    width: '130pt',
    height: '130pt',
    margin: '10px 30px 0 10px',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  blankColumn: {
    flex: 1,
  },
  titleContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  card: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    minWidth: 0,
    padding: '30px 20px',
    marginBottom: '10px',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  displayEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingTop: 10,
  },
  content: {
    position: 'relative',
    padding: '24px',
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '10px',
    paddingTop: '35px',
    borderBottomWidth: 1.3,
    borderBottomColor: 'gray',
    borderBottomStyle: 'solid',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 25,
    maxHeight: 100,
  },
  column: { flexDirection: 'row', alignItems: 'center' },
  specialColumn: { display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' },
  fontStyle2: { fontSize: '12', fontWeight: 'normal', fontFamily: 'Roboto' },
  fontStyle3: { fontSize: '10', fontWeight: '500', fontFamily: 'Roboto' },
  titleHead: { fontSize: 14, fontWeight: 'bold', fontFamily: 'Roboto' },
  itemHead: { fontSize: 12, fontWeight: 'bold', fontFamily: 'Roboto' },
  listTitle: { fontSize: 10, fontWeight: 'normal', fontFamily: 'Roboto' },
  listItemData: { fontSize: 10, fontWeight: 'normal', fontFamily: 'Roboto' },
})

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: robotoNormal,
      fontWeight: 'normal',
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
    {
      src: robotoMedium,
      fontWeight: 'light',
    },
  ],
})

const header = () => {
  return (
    <View style={styles.headerColumn}>
      <View style={styles.blankColumn}></View>
      <View style={styles.titleContent}>
        <Text style={styles.title}>Mandala Indian Cusine</Text>
      </View>
      <View style={styles.blankColumn} />
    </View>
  )
}

const orderBody = (id, order) => {
  return (
    <View style={styles.card}>
      <View style={styles.spaceBetween}>
        <Text style={styles.titleHead}>Order Details</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.spaceBetween}>
          <View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Order Code: </Text>
              <Text style={styles.fontStyle2}>{order?.orderCode}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Name: </Text>
              <Text style={styles.fontStyle2}>{order?.basicInfo?.name}</Text>
            </View>
            {order?.basicInfo?.contactNumber !== '' && (
              <View style={styles.column}>
                <Text style={styles.itemHead}>Phone No.: </Text>
                <Text style={styles.fontStyle2}>
                  {order?.basicInfo?.phoneCode || '+1'}
                  {normalizePhoneInput(order?.basicInfo?.contactNumber)}
                </Text>
              </View>
            )}
            <View style={styles.column}>
              <Text style={styles.itemHead}>Pick Up Time: </Text>
              <Text style={styles.fontStyle2}>
                {moment(new Date(order?.pickupInfo?.pickupTime)).utc(false).format(' MMM Do, h:mm a')}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Pick Up By: </Text>
              <Text style={styles.fontStyle2}>{order?.pickupInfo?.pickUpBy || order?.basicInfo?.name}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Payment Type: </Text>
              <Text style={styles.fontStyle2}>{order?.paymentType === 2 ? 'Card Payment' : 'Pay At Store'}</Text>
            </View>
          </View>
          <View>
            <View style={styles.column}>
              <Text style={styles.fontStyle2}>{order?.receiveSms ? 'Receiving SMS' : 'Not Receiving SMS'}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Payment Status: </Text>
              <Text style={styles.fontStyle2}>{paymentStatus(order?.paymentStatus)}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.itemHead}>Order Status: </Text>
              <Text style={styles.fontStyle2}>{orderStatus(order?.aggregateStatus)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.listHeader}>
          <View style={{ flex: 0.5 }}>
            <Text style={styles.listTitle}>Qty</Text>
          </View>
          <View style={{ flex: 3 }}>
            <Text style={styles.listTitle}>Product Name</Text>
          </View>
          <View style={{ flex: 1, textAlign: 'right' }}>
            <Text style={styles.listTitle}>Total Price</Text>
          </View>
        </View>
        {order?.productArray?.map((item, index) => (
          <View
            style={{ borderBottomWidth: 1.3, borderBottomColor: 'gray', borderBottomStyle: 'solid' }}
            key={`product${index}`}
            wrap
          >
            <View style={styles.listItem}>
              <View style={{ flex: 0.5 }}>
                <Text style={styles.listItemData}>{item.quantity}</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text style={styles.listItemData}>{item.productName}</Text>
                <Text style={styles.listItemData}> - Spice Level: {spiceLevelStatus(item?.spiceLevel)}</Text>
                {item?.specialInstruction && (
                  <Text style={styles.listItemData}> - Special Instruction: {item?.specialInstruction}</Text>
                )}
              </View>
              <View style={{ flex: 1, textAlign: 'right' }}>
                <Text style={styles.listItemData}>${Number(item.price).toFixed(2)}</Text>
              </View>
            </View>
          </View>
        ))}
        <View style={styles.displayEnd}>
          <View style={{ flex: 2 }} />
          <View
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Text style={styles.itemHead}>Sub Total:</Text>
            <Text style={styles.itemHead}>${Number(order?.subTotal).toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.displayEnd}>
          <View style={{ flex: 2 }} />
          <View
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Text style={styles.itemHead}>Tax:</Text>
            <Text style={styles.itemHead}>${Number(order?.tax).toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.displayEnd}>
          <View style={{ flex: 2 }} />
          <View
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Text style={styles.itemHead}>Tips::</Text>
            <Text style={styles.itemHead}>${order?.tips}</Text>
          </View>
        </View>
        <View style={styles.displayEnd}>
          <View style={{ flex: 2 }} />
          <View
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Text style={styles.itemHead}>Total:</Text>
            <Text style={styles.itemHead}>${Number(order?.totalPrice).toFixed(2)}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default function OrderDetailPDF({ id, order }) {
  return pdf(
    <Document>
      <Page size="A4" style={styles.container} wrap>
        {header()}
        {orderBody(id, order)}
      </Page>
    </Document>
  )
    .toBlob()
    .then((e) => {
      const url = URL.createObjectURL(e)
      window.open(url, '__blank')
    })
}
