import { takeEvery, takeLatest, all } from 'redux-saga/effects'
import API from '../services/api'
import {
  loginRequest,
  logoutRequest,
  categoryRequest,
  addproductRequest,
  getproductRequest,
  getproductdetailRequest,
  createnewgalleryRequest,
  getgallerylistRequest,
  editgalleryRequest,
  deleteRequest,
  editproductRequest,
  getorderlistRequest,
  getorderdetailRequest,
  updateorderRequest,
  updateorderpaymentRequest,
  setnotificationtokenRequest,
  getallnotificationRequest,
  markallasreadRequest,
  markasreadRequest,
  addreservationRequest,
  getwaitinglistRequest,
  getwaitinglistdetailRequest,
  updatereservationRequest,
  updatewaitingRequest,
  sendsmsorderRequest,
  getbookinglistRequest,
  getbookingdetailRequest,
  createreservationRequest,
  getsettingRequest,
  postsettingRequest,
  postcategorysettingRequest,
  addcategoryRequest,
  editcategoryRequest,
} from './app'
import { AppTypes } from '../actions/app'

const api = API.create()
export default function* root() {
  yield all([
    takeLatest(AppTypes.LOGIN_REQUEST, loginRequest, api),
    takeLatest(AppTypes.LOGOUT_REQUEST, logoutRequest, api),
    takeLatest(AppTypes.CATEGORY_REQUEST, categoryRequest, api),
    takeLatest(AppTypes.ADDCATEGORY_REQUEST, addcategoryRequest, api),
    takeLatest(AppTypes.EDITCATEGORY_REQUEST, editcategoryRequest, api),
    takeLatest(AppTypes.ADDPRODUCT_REQUEST, addproductRequest, api),
    takeLatest(AppTypes.GETPRODUCT_REQUEST, getproductRequest, api),
    takeLatest(AppTypes.GETPRODUCTDETAIL_REQUEST, getproductdetailRequest, api),
    takeLatest(AppTypes.CREATENEWGALLERY_REQUEST, createnewgalleryRequest, api),
    takeLatest(AppTypes.GETGALLERYLIST_REQUEST, getgallerylistRequest, api),
    takeLatest(AppTypes.EDITGALLERY_REQUEST, editgalleryRequest, api),
    takeLatest(AppTypes.DELETE_REQUEST, deleteRequest, api),
    takeLatest(AppTypes.EDITPRODUCT_REQUEST, editproductRequest, api),
    takeLatest(AppTypes.GETORDERLIST_REQUEST, getorderlistRequest, api),
    takeLatest(AppTypes.GETORDERDETAIL_REQUEST, getorderdetailRequest, api),
    takeLatest(AppTypes.UPDATEORDER_REQUEST, updateorderRequest, api),
    takeLatest(AppTypes.UPDATEORDERPAYMENT_REQUEST, updateorderpaymentRequest, api),
    takeLatest(AppTypes.SENDSMSORDER_REQUEST, sendsmsorderRequest, api),
    takeLatest(AppTypes.SETNOTIFICATIONTOKEN_REQUEST, setnotificationtokenRequest, api),
    takeLatest(AppTypes.GETALLNOTIFICATION_REQUEST, getallnotificationRequest, api),
    takeLatest(AppTypes.MARKALLASREAD_REQUEST, markallasreadRequest, api),
    takeLatest(AppTypes.MARKASREAD_REQUEST, markasreadRequest, api),
    takeLatest(AppTypes.ADDRESERVATION_REQUEST, addreservationRequest, api),
    takeLatest(AppTypes.CREATERESERVATION_REQUEST, createreservationRequest, api),
    takeLatest(AppTypes.UPDATERESERVATION_REQUEST, updatereservationRequest, api),
    takeLatest(AppTypes.UPDATEWAITING_REQUEST, updatewaitingRequest, api),
    takeLatest(AppTypes.GETBOOKINGLIST_REQUEST, getbookinglistRequest, api),
    takeLatest(AppTypes.GETBOOKINGDETAIL_REQUEST, getbookingdetailRequest, api),
    takeLatest(AppTypes.GETWAITINGLIST_REQUEST, getwaitinglistRequest, api),
    takeLatest(AppTypes.GETWAITINGLISTDETAIL_REQUEST, getwaitinglistdetailRequest, api),
    takeLatest(AppTypes.GETSETTING_REQUEST, getsettingRequest, api),
    takeLatest(AppTypes.POSTSETTING_REQUEST, postsettingRequest, api),
    takeLatest(AppTypes.POSTCATEGORYSETTING_REQUEST, postcategorysettingRequest, api),
  ])
}
