import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'

export default function Product({ data, sort, onEdit, onDelete }) {
  const sortedData = _.orderBy(data, ['isAvailable'], [sort])
  return (
    <div className="row mt-1 mx-2">
      {sortedData?.map((item) => {
        return (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={item._id}>
            <div className={classNames(!item?.isAvailable && 'bg-red', 'ms-card')}>
              <div className="ms-card-body">
                <div className="new">
                  <h6 className="mb-0">{item?.productName} </h6>
                  <h6 className="ms-text-primary mb-0">{`$${item?.price}`}</h6>
                </div>
                <p style={{ wordBreak: 'break-all' }}>{item.description}</p>
                <div className="new mb-0">
                  <button
                    type="button"
                    className="btn grid-btn mt-0 btn-sm btn-primary"
                    style={{ fontSize: '18px' }}
                    onClick={() => onDelete(item)}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="btn grid-btn mt-0 btn-sm btn-secondary"
                    style={{ fontSize: '18px' }}
                    onClick={() => onEdit(item)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
