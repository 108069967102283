import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import Popup from 'reactjs-popup'
import OutsideClickHandler from 'react-outside-click-handler'
import { contains, equals, isEmpty, split, find, propEq } from 'ramda'
import { Animations, Icon } from '@components'

import AppActions from '~/actions/app'
import { loading } from '~/services/utils'
import logo from '~/assets/img/logo-final.png'
import Notification from './Notification'
import './Header.scss'

class Header extends Component {
  state = {
    openNotification: false,
    profile: false,
    rightnav: false,
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.props.getAllNotification()
    }
  }

  toggleNotification = () => {
    const { notifications } = this.props
    const { openNotification } = this.state
    if (notifications?.pagination?.totalRecords > 0) {
      this.setState({ openNotification: !openNotification })
    }
  }

  handleUpdateNotifications = (id, payload, after, route) => {
    this.props.markAsRead(id, payload, after, route)
  }

  renderNotification() {
    const { notifications, loading, getAllNotification, markAllAsRead, markAsRead } = this.props
    const { openNotification } = this.state

    return (
      <Animations.Popup className="notification-popup" enter={10} exit={0} opened={openNotification}>
        <Notification
          loading={loading}
          notifications={notifications}
          onClose={() => this.setState({ openNotification: false })}
          onFetch={(e) => getAllNotification(e)}
          onMarkAllRead={(e) => markAllAsRead(e)}
          onUpdate={this.handleUpdateNotifications}
        />
      </Animations.Popup>
    )
  }

  render() {
    const { loggedIn, history } = this.props
    const { openNotification, profile, rightnav } = this.state
    const { notifications } = this.props

    return (
      <>
        <nav className="navbar ms-navbar">
          {history.location.pathname !== '/home/dashboard' ? (
            <div
              className="ms-aside-toggler ms-toggler pl-0"
              data-target="ms-side-nav"
              data-toggle="slideLeft"
              onClick={() => this.props.navbar()}
            >
              <span className="ms-toggler-bar bg-primary"></span>
              <span className="ms-toggler-bar bg-primary"></span>
              <span className="ms-toggler-bar bg-primary"></span>
            </div>
          ) : (
            <div className="ms-aside-toggler ms-toggler pl-0"></div>
          )}
          <ul
            className={classNames('ms-nav-list ms-inline mb-0', rightnav && 'ms-slide-down')}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Popup
              trigger={
                <Button className={classNames('bell border-0 d-center', openNotification && 'active')}>
                  <div className="bell-icon">
                    <Icon name="fas fa-bell" color="blue" size={26} />
                    {notifications?.badge > 0 && (
                      <div className="badge-icon">
                        <p className="dsl-w10 bold m-0">{notifications.badge > 99 ? '99+' : notifications.badge}</p>
                      </div>
                    )}
                  </div>
                </Button>
              }
              arrow={false}
              className="ds-notification"
              open={openNotification}
              position="bottom right"
              contentStyle={{ padding: 0, width: 360 }}
              onOpen={this.toggleNotification}
              onClose={() => this.setState({ openNotification: false })}
            >
              {this.renderNotification()}
            </Popup>

            <OutsideClickHandler onOutsideClick={() => this.setState({ profile: false })}>
              <li className={classNames('ms-nav-item ms-nav-user dropdown', profile && 'show')}>
                <a aria-haspopup="true" aria-expanded={profile} onClick={() => this.setState({ profile: !profile })}>
                  <img className="ms-user-img ms-img-round float-right" src={logo} />
                </a>
                <ul
                  className={classNames('dropdown-menu dropdown-menu-right user-dropdown', profile && 'show')}
                  aria-labelledby="userDropdown"
                >
                  <li className="dropdown-menu-header">
                    <h6 className="dropdown-header ms-inline m-0">
                      <span className="text-disabled">Hello, Admin</span>
                    </h6>
                  </li>
                  <li className="dropdown-menu-footer">
                    <a className="media fs-14 p-2" onClick={() => this.props.logoutRequest('Header')}>
                      <span>
                        <i className="flaticon-shut-down mr-2"></i> Logout
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </OutsideClickHandler>
          </ul>
          <div
            className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
            onClick={() => this.setState({ rightnav: !rightnav })}
          >
            <span className="ms-toggler-bar bg-primary"></span>
            <span className="ms-toggler-bar bg-primary"></span>
            <span className="ms-toggler-bar bg-primary"></span>
          </div>
        </nav>
      </>
    )
  }
}

Header.propTypes = {
  notifications: PropTypes.shape({
    badge: PropTypes.number,
    data: PropTypes.array,
    pagination: PropTypes.object,
  }),
}

Header.defaultProps = {
  notifications: {
    badge: 0,
    data: [],
    pagination: {},
  },
}

const mapStateToProps = (state) => ({
  loading: loading(state.app.status),
  loggedIn: !isEmpty(state.app.token),
  notifications: state.app.notification,
  token: state.app.token,
  name: state.app.name,
})

const mapDispatchToProps = (dispatch) => ({
  getAllNotification: (e) => dispatch(AppActions.getallnotificationRequest(e)),
  markAllAsRead: (e) => dispatch(AppActions.markallasreadRequest(e)),
  markAsRead: (id, e, route) => dispatch(AppActions.markasreadRequest(id, e, route)),
  logoutRequest: (calledFrom) => dispatch(AppActions.logoutRequest(calledFrom)),
  navbar: () => dispatch(AppActions.navbarRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
