import { createReducer } from 'reduxsauce'
import { produce } from 'immer'
import { clone, concat, dropLast, equals, has, includes, isEmpty, isNil, merge, prepend, replace } from 'ramda'
import { AppTypes } from '../actions/app'
import { removeStatus, version } from '~/services/utils'
import { history } from '.'

const initialState = {
  status: [],
  version: '1.0.0',

  // user data
  token: '',
  name: '',

  category: {
    data: [],
    pagination: {},
  },
  menuList: [],
  menuItem: {},

  slider: {
    data: [],
    pagination: {},
  },

  order: {
    data: [],
    pagination: {},
  },

  orderItem: {},

  bookings: {
    data: [],
    pagination: {},
  },

  booking: {},
  reservations: {
    data: [],
    pagination: {},
  },

  reservation: {},

  notification: {
    badge: 0,
    data: [],
    pagination: {},
  },

  navbar: false,

  settings: {
    orderStatus: {},
    reservationStatus: {},
    taxRate: {},
  },

  // Modals
  modalType: '',
  modalData: {
    before: null,
    after: null,
  },
  modalCallBack: {
    onYes: () => {},
    onNo: () => {},
  },

  // The latest 3 route histories
  locations: ['', '', ''],
}

const loginRequest = produce((draft, action) => {
  draft.status.push('pending-alg')
  draft.version = version()
})
const loginSuccess = produce((draft, action) => {
  const { accessToken } = action.response
  draft.token = accessToken
  draft.status = removeStatus('pending-alg', draft.status)
})
const loginFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-alg', draft.status)
})

const logoutRequest = produce((draft, action) => {
  draft.status = []
})
const logoutSuccess = produce((draft, action) => {
  draft.status = []
})
const logoutFailure = produce((draft, action) => {
  draft.status = []
})

const categoryRequest = produce((draft, action) => {
  draft.status.push('pending-acr')
})
const categorySuccess = produce((draft, action) => {
  draft.category = action.response
  draft.status = removeStatus('pending-acr', draft.status)
})
const categoryFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-acr', draft.status)
})

const addcategoryRequest = produce((draft, action) => {
  draft.status.push('pending-aac')
})
const addcategorySuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-aac', draft.status)
})
const addcategoryFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-aac', draft.status)
})

const editcategoryRequest = produce((draft, action) => {
  draft.status.push('pending-aec')
})
const editcategorySuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-aec', draft.status)
})
const editcategoryFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-aec', draft.status)
})

const addproductRequest = produce((draft, action) => {
  draft.status.push('pending-apr')
})
const addproductSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-apr', draft.status)
})
const addproductFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-apr', draft.status)
})

const getproductRequest = produce((draft, action) => {
  draft.status.push('pending-agp')
})
const getproductSuccess = produce((draft, action) => {
  draft.menuList = action.response
  draft.status = removeStatus('pending-agp', draft.status)
})
const getproductFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-agp', draft.status)
})

const getproductdetailRequest = produce((draft, action) => {
  draft.status.push('pending-gpd')
})
const getproductdetailSuccess = produce((draft, action) => {
  const { data } = action.response
  draft.menuItem = data
  draft.status = removeStatus('pending-gpd', draft.status)
})
const getproductdetailFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-gpd', draft.status)
})

const editproductRequest = produce((draft, action) => {
  draft.status.push('pending-epr')
})
const editproductSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-epr', draft.status)
})
const editproductFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-epr', draft.status)
})

const createnewgalleryRequest = produce((draft, action) => {
  draft.status.push('pending-cng')
})
const createnewgallerySuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-cng', draft.status)
})
const createnewgalleryFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-cng', draft.status)
})

const getgallerylistRequest = produce((draft, action) => {
  draft.status.push('pending-ggl')
})
const getgallerylistSuccess = produce((draft, action) => {
  draft.slider = action.response
  draft.status = removeStatus('pending-ggl', draft.status)
})
const getgallerylistFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-ggl', draft.status)
})

const editgalleryRequest = produce((draft, action) => {
  draft.status.push('pending-egr')
})
const editgallerySuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-egr', draft.status)
})
const editgalleryFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-egr', draft.status)
})

const deleteRequest = produce((draft, action) => {
  draft.status.push('pending-adr')
})
const deleteSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-adr', draft.status)
})
const deleteFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-adr', draft.status)
})

const getorderlistRequest = produce((draft, action) => {
  draft.status.push('pending-gol')
})
const getorderlistSuccess = produce((draft, action) => {
  draft.order = action.response
  draft.status = removeStatus('pending-gol', draft.status)
})
const getorderlistFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-gol', draft.status)
})

const getorderdetailRequest = produce((draft, action) => {
  draft.status.push('pending-god')
})
const getorderdetailSuccess = produce((draft, action) => {
  draft.orderItem = action.response.data
  draft.status = removeStatus('pending-god', draft.status)
})
const getorderdetailFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-god', draft.status)
})

const updateorderRequest = produce((draft, action) => {
  draft.status.push('pending-uor')
})
const updateorderSuccess = produce((draft, action) => {
  // draft.orderItem = action.response
  draft.status = removeStatus('pending-uor', draft.status)
})
const updateorderFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-uor', draft.status)
})

const updateorderpaymentRequest = produce((draft, action) => {
  draft.status.push('pending-uop')
})
const updateorderpaymentSuccess = produce((draft, action) => {
  // draft.orderItem = action.response
  draft.status = removeStatus('pending-uop', draft.status)
})
const updateorderpaymentFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-uop', draft.status)
})

// waiiting list
const addreservationRequest = produce((draft, action) => {
  draft.status.push('pending-awl')
})
const addreservationSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-awl', draft.status)
})
const addreservationFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-awl', draft.status)
})

// reservation list

const createreservationRequest = produce((draft, action) => {
  draft.status.push('pending-arr')
})
const createreservationSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-arr', draft.status)
})
const createreservationFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-arr', draft.status)
})

const getwaitinglistRequest = produce((draft, action) => {
  draft.status.push('pending-grl')
})
const getwaitinglistSuccess = produce((draft, action) => {
  draft.reservations = action.response
  draft.status = removeStatus('pending-grl', draft.status)
})
const getwaitinglistFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-grl', draft.status)
})

const getwaitinglistdetailRequest = produce((draft, action) => {
  draft.status.push('pending-grd')
})
const getwaitinglistdetailSuccess = produce((draft, action) => {
  draft.reservation = action.response.data
  draft.status = removeStatus('pending-grd', draft.status)
})
const getwaitinglistdetailFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-grd', draft.status)
})

const getbookinglistRequest = produce((draft, action) => {
  draft.status.push('pending-grl')
})
const getbookinglistSuccess = produce((draft, action) => {
  draft.bookings = action.response
  draft.status = removeStatus('pending-grl', draft.status)
})
const getbookinglistFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-grl', draft.status)
})

const getbookingdetailRequest = produce((draft, action) => {
  draft.status.push('pending-grd')
})
const getbookingdetailSuccess = produce((draft, action) => {
  draft.booking = action.response.data
  draft.status = removeStatus('pending-grd', draft.status)
})
const getbookingdetailFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-grd', draft.status)
})

const updatereservationRequest = produce((draft, action) => {
  draft.status.push('pending-ura')
})
const updatereservationSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-ura', draft.status)
})
const updatereservationFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-ura', draft.status)
})

const updatewaitingRequest = produce((draft, action) => {
  draft.status.push('pending-uwr')
})
const updatewaitingSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-uwr', draft.status)
})
const updatewaitingFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-uwr', draft.status)
})

const sendsmsorderRequest = produce((draft, action) => {
  draft.status.push('pending-sso')
})
const sendsmsorderSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-sso', draft.status)
})
const sendsmsorderFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-sso', draft.status)
})

const setnotificationtokenRequest = produce((draft, action) => {})
const setnotificationtokenSuccess = produce((draft, action) => {})
const setnotificationtokenFailure = produce((draft, action) => {})

const getallnotificationRequest = produce((draft, action) => {
  draft.status.push('pending-gan')
})
const getallnotificationSuccess = produce((draft, action) => {
  const { data, pagination, badge } = action.response
  if (pagination?.currentPage === 1) {
    draft.notification = action.response
  } else {
    let _data = clone(draft.notification)
    let notificationData
    notificationData = {
      ...action.response,
      data: concat(_data?.data || [], data),
    }
    draft.notification = notificationData
  }

  draft.status = removeStatus('pending-gan', draft.status)
})
const getallnotificationFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-gan', draft.status)
})

const markallasreadRequest = produce((draft, action) => {
  draft.status.push('pending-maa')
})
const markallasreadSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-maa', draft.status)
})
const markallasreadFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-maa', draft.status)
})

const markasreadRequest = produce((draft, action) => {
  draft.status.push('pending-mar')
})
const markasreadSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-mar', draft.status)
})
const markasreadFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-mar', draft.status)
})

//Settings
const getsettingRequest = produce((draft, action) => {
  draft.status.push('pending-gsr')
})
const getsettingSuccess = produce((draft, action) => {
  draft.settings = action.response.data
  draft.status = removeStatus('pending-gsr', draft.status)
})
const getsettingFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-gsr', draft.status)
})

const postsettingRequest = produce((draft, action) => {
  draft.status.push('pending-psr')
})
const postsettingSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-psr', draft.status)
})
const postsettingFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-psr', draft.status)
})

const postcategorysettingRequest = produce((draft, action) => {
  draft.status.push('pending-pcs')
})
const postcategorysettingSuccess = produce((draft, action) => {
  draft.status = removeStatus('pending-pcs', draft.status)
})
const postcategorysettingFailure = produce((draft, action) => {
  draft.status = removeStatus('pending-pcs', draft.status)
})

//Nav Request
const navbarRequest = produce((draft, action) => {
  draft.navbar = !draft.navbar
})

//Location Request
const locationsRequest = produce((draft, action) => {
  draft.locations = prepend(action.payload.route, draft.locations)
  draft.locations = dropLast(1, draft.locations)
})

const versionRequest = produce((draft, action) => {
  draft.version = version()
})

// Show Modals
const modalRequest = produce((draft, action) => {
  const { type, data, callBack } = action.payload
  let before = {},
    after = {}
  if (!isNil(data)) {
    if (has('before', data)) before = data.before
    if (has('after', data)) after = data.after
  }

  // When modal type is null, we just update only modal data
  if (isNil(type)) {
    draft.modalData = { before, after }
    return
  }

  // Confirmation modal
  if (equals(type, 'Confirm')) {
    // Show only confirmation modal
    if (isEmpty(draft.modalType) || isNil(draft.modalType)) {
      draft.modalType = type
      draft.modalData = { before, after }
      draft.modalCallBack = callBack
      return
    }

    // Show two modals at the same time
    draft.modalType = includes('Confirm', draft.modalType)
      ? replace(' - Confirm', '', draft.modalType)
      : draft.modalType + ' - ' + type
    draft.modalData = {
      before: merge(draft.modalData.before, {
        __title: before.title,
        __body: before.body,
        __description: before.description,
      }),
      after: draft.modalData.after,
    }
    draft.modalCallBack = callBack
    return
  }

  // Close modal or show the other modal normally
  draft.modalType = equals(draft.modalType, type) ? '' : type
  draft.modalData = { before, after }
  draft.modalCallBack = callBack
})

const clearRequest = produce((draft, action) => (draft = initialState))

export const reducer = createReducer(initialState, {
  [AppTypes.LOGIN_REQUEST]: loginRequest,
  [AppTypes.LOGIN_SUCCESS]: loginSuccess,
  [AppTypes.LOGIN_FAILURE]: loginFailure,

  [AppTypes.LOGOUT_REQUEST]: logoutRequest,
  [AppTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [AppTypes.LOGOUT_FAILURE]: logoutFailure,

  //category

  [AppTypes.CATEGORY_REQUEST]: categoryRequest,
  [AppTypes.CATEGORY_SUCCESS]: categorySuccess,
  [AppTypes.CATEGORY_FAILURE]: categoryFailure,

  [AppTypes.ADDCATEGORY_REQUEST]: addcategoryRequest,
  [AppTypes.ADDCATEGORY_SUCCESS]: addcategorySuccess,
  [AppTypes.ADDCATEGORY_FAILURE]: addcategoryFailure,

  [AppTypes.EDITCATEGORY_REQUEST]: editcategoryRequest,
  [AppTypes.EDITCATEGORY_SUCCESS]: editcategorySuccess,
  [AppTypes.EDITCATEGORY_FAILURE]: editcategoryFailure,

  [AppTypes.ADDPRODUCT_REQUEST]: addproductRequest,
  [AppTypes.ADDPRODUCT_SUCCESS]: addproductSuccess,
  [AppTypes.ADDPRODUCT_FAILURE]: addproductFailure,

  [AppTypes.GETPRODUCT_REQUEST]: getproductRequest,
  [AppTypes.GETPRODUCT_SUCCESS]: getproductSuccess,
  [AppTypes.GETPRODUCT_FAILURE]: getproductFailure,

  [AppTypes.GETPRODUCTDETAIL_REQUEST]: getproductdetailRequest,
  [AppTypes.GETPRODUCTDETAIL_SUCCESS]: getproductdetailSuccess,
  [AppTypes.GETPRODUCTDETAIL_FAILURE]: getproductdetailFailure,

  [AppTypes.EDITPRODUCT_REQUEST]: editproductRequest,
  [AppTypes.EDITPRODUCT_SUCCESS]: editproductSuccess,
  [AppTypes.EDITPRODUCT_FAILURE]: editproductFailure,

  [AppTypes.CREATENEWGALLERY_REQUEST]: createnewgalleryRequest,
  [AppTypes.CREATENEWGALLERY_SUCCESS]: createnewgallerySuccess,
  [AppTypes.CREATENEWGALLERY_FAILURE]: createnewgalleryFailure,

  [AppTypes.GETGALLERYLIST_REQUEST]: getgallerylistRequest,
  [AppTypes.GETGALLERYLIST_SUCCESS]: getgallerylistSuccess,
  [AppTypes.GETGALLERYLIST_FAILURE]: getgallerylistFailure,

  [AppTypes.EDITGALLERY_REQUEST]: editgalleryRequest,
  [AppTypes.EDITGALLERY_SUCCESS]: editgallerySuccess,
  [AppTypes.EDITGALLERY_FAILURE]: editgalleryFailure,

  [AppTypes.DELETE_REQUEST]: deleteRequest,
  [AppTypes.DELETE_SUCCESS]: deleteSuccess,
  [AppTypes.DELETE_FAILURE]: deleteFailure,

  [AppTypes.GETORDERLIST_REQUEST]: getorderlistRequest,
  [AppTypes.GETORDERLIST_SUCCESS]: getorderlistSuccess,
  [AppTypes.GETORDERLIST_FAILURE]: getorderlistFailure,

  [AppTypes.GETORDERDETAIL_REQUEST]: getorderdetailRequest,
  [AppTypes.GETORDERDETAIL_SUCCESS]: getorderdetailSuccess,
  [AppTypes.GETORDERDETAIL_FAILURE]: getorderdetailFailure,

  [AppTypes.UPDATEORDER_REQUEST]: updateorderRequest,
  [AppTypes.UPDATEORDER_SUCCESS]: updateorderSuccess,
  [AppTypes.UPDATEORDER_FAILURE]: updateorderFailure,

  [AppTypes.UPDATEORDERPAYMENT_REQUEST]: updateorderpaymentRequest,
  [AppTypes.UPDATEORDERPAYMENT_SUCCESS]: updateorderpaymentSuccess,
  [AppTypes.UPDATEORDERPAYMENT_FAILURE]: updateorderpaymentFailure,

  // waiting list
  [AppTypes.ADDRESERVATION_REQUEST]: addreservationRequest,
  [AppTypes.ADDRESERVATION_SUCCESS]: addreservationSuccess,
  [AppTypes.ADDRESERVATION_FAILURE]: addreservationFailure,

  [AppTypes.UPDATEWAITING_REQUEST]: updatewaitingRequest,
  [AppTypes.UPDATEWAITING_SUCCESS]: updatewaitingSuccess,
  [AppTypes.UPDATEWAITING_FAILURE]: updatewaitingFailure,

  // reservation
  [AppTypes.CREATERESERVATION_REQUEST]: createreservationRequest,
  [AppTypes.CREATERESERVATION_SUCCESS]: createreservationSuccess,
  [AppTypes.CREATERESERVATION_FAILURE]: createreservationFailure,

  [AppTypes.GETBOOKINGLIST_REQUEST]: getbookinglistRequest,
  [AppTypes.GETBOOKINGLIST_SUCCESS]: getbookinglistSuccess,
  [AppTypes.GETBOOKINGLIST_FAILURE]: getbookinglistFailure,

  [AppTypes.GETBOOKINGDETAIL_REQUEST]: getbookingdetailRequest,
  [AppTypes.GETBOOKINGDETAIL_SUCCESS]: getbookingdetailSuccess,
  [AppTypes.GETBOOKINGDETAIL_FAILURE]: getbookingdetailFailure,

  [AppTypes.GETWAITINGLIST_REQUEST]: getwaitinglistRequest,
  [AppTypes.GETWAITINGLIST_SUCCESS]: getwaitinglistSuccess,
  [AppTypes.GETWAITINGLIST_FAILURE]: getwaitinglistFailure,

  [AppTypes.GETWAITINGLISTDETAIL_REQUEST]: getwaitinglistdetailRequest,
  [AppTypes.GETWAITINGLISTDETAIL_SUCCESS]: getwaitinglistdetailSuccess,
  [AppTypes.GETWAITINGLISTDETAIL_FAILURE]: getwaitinglistdetailFailure,

  [AppTypes.UPDATERESERVATION_REQUEST]: updatereservationRequest,
  [AppTypes.UPDATERESERVATION_SUCCESS]: updatereservationSuccess,
  [AppTypes.UPDATERESERVATION_FAILURE]: updatereservationFailure,

  [AppTypes.SETNOTIFICATIONTOKEN_REQUEST]: setnotificationtokenRequest,
  [AppTypes.SETNOTIFICATIONTOKEN_SUCCESS]: setnotificationtokenSuccess,
  [AppTypes.SETNOTIFICATIONTOKEN_FAILURE]: setnotificationtokenFailure,

  [AppTypes.GETALLNOTIFICATION_REQUEST]: getallnotificationRequest,
  [AppTypes.GETALLNOTIFICATION_SUCCESS]: getallnotificationSuccess,
  [AppTypes.GETALLNOTIFICATION_FAILURE]: getallnotificationFailure,

  [AppTypes.MARKALLASREAD_REQUEST]: markallasreadRequest,
  [AppTypes.MARKALLASREAD_SUCCESS]: markallasreadSuccess,
  [AppTypes.MARKALLASREAD_FAILURE]: markallasreadFailure,

  [AppTypes.MARKASREAD_REQUEST]: markasreadRequest,
  [AppTypes.MARKASREAD_SUCCESS]: markasreadSuccess,
  [AppTypes.MARKASREAD_FAILURE]: markasreadFailure,

  [AppTypes.SENDSMSORDER_REQUEST]: sendsmsorderRequest,
  [AppTypes.SENDSMSORDER_SUCCESS]: sendsmsorderSuccess,
  [AppTypes.SENDSMSORDER_FAILURE]: sendsmsorderFailure,

  //Settings
  [AppTypes.GETSETTING_REQUEST]: getsettingRequest,
  [AppTypes.GETSETTING_SUCCESS]: getsettingSuccess,
  [AppTypes.GETSETTING_FAILURE]: getsettingFailure,

  [AppTypes.POSTSETTING_REQUEST]: postsettingRequest,
  [AppTypes.POSTSETTING_SUCCESS]: postsettingSuccess,
  [AppTypes.POSTSETTING_FAILURE]: postsettingFailure,

  [AppTypes.POSTCATEGORYSETTING_REQUEST]: postcategorysettingRequest,
  [AppTypes.POSTCATEGORYSETTING_SUCCESS]: postcategorysettingSuccess,
  [AppTypes.POSTCATEGORYSETTING_FAILURE]: postcategorysettingFailure,

  [AppTypes.NAVBAR_REQUEST]: navbarRequest,

  [AppTypes.VERSION_REQUEST]: versionRequest,
  [AppTypes.LOCATIONS_REQUEST]: locationsRequest,
  [AppTypes.MODAL_REQUEST]: modalRequest,

  [AppTypes.CLEAR_REQUEST]: clearRequest,
})
