import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { Dropdown, EditDropdown } from '@components'

const sortOption = [
  { _id: 1, name: 'Order' },
  { _id: 2, name: 'Reservation' },
]

export class Notification extends Component {
  state = {
    page: 1,
    type: null,
  }

  componentDidMount() {
    this.props.onFetch()
  }

  handleDropdown = (notification) => (e) => {
    const { type } = this.state
    const payload = type ? { type } : null
    if (e === 'mark as read') {
      this.props.onUpdate(notification._id, payload, null)
    }
  }

  handleClickItem = (notification) => () => {
    const { type } = this.state
    console.log(type)
    const payload = type ? { type } : null
    let route = null
    const notificationType = notification?.data?.type
    switch (notificationType) {
      case 'newOrder':
        route = `/home/order/${notification?.data?.orderId}`
        break
      case 'updatedOrder':
        route = `/home/order/${notification?.data?.orderId}`
        break
      case 'newReservation':
        route = `/home/reservation/${notification?.data?.bookingId}`
        break
      case 'updateReservation':
        route = `/home/reservation/${notification?.data?.bookingId}`
        break
      default:
        break
    }
    this.props.onUpdate(notification._id, payload, route)
    this.props.onClose()
  }

  handleRefresh = () => {
    const { type } = this.state
    const { notifications } = this.props
    const { pagination } = notifications
    const { currentPage, hasNextPage } = pagination
    if (hasNextPage) {
      const page = currentPage + 1
      this.props.onFetch({ page, type })
      this.setState({ page })
    }
  }

  handleChangeOption = (option) => {
    const type = option[0]?._id
    this.props.onFetch({ type })
    this.setState({ type })
  }

  renderLoader(loading = false) {
    const { notifications } = this.props
    const { pagination } = notifications
    const { currentPage, hasNextPage } = pagination
    if (!hasNextPage) return null
    return (
      <div className="d-flex justify-content-center align-items-center p-3">
        <p className="dsl-m12 mb-0">Loading More</p>
        {loading && <div className="loading-spinner ml-2" />}
      </div>
    )
  }

  render() {
    const { type } = this.state
    const { loading, notifications } = this.props
    const { data, pagination, badge } = notifications
    return (
      <div id="notification-container" className="notification-container card">
        <div className="d-h-between">
          <Dropdown
            align="right"
            title="Type"
            data={sortOption}
            width={150}
            returnBy="data"
            getValue={(e) => e.name}
            onChange={this.handleChangeOption}
          />
          <button
            type="button"
            className="btn btn-square btn-link p-0 m-0"
            onClick={() => this.props.onMarkAllRead({ type })}
          >
            Mark All As Read
          </button>
        </div>

        {data?.length > 0 && (
          <InfiniteScroll
            hasMore
            dataLength={data.length}
            loader={this.renderLoader(loading)}
            next={this.handleRefresh}
            scrollableTarget="notification-container"
          >
            {data?.map((notification) => {
              const { _id, body, isRead, title, createdAt, data } = notification
              const createdDate = moment.utc(createdAt).local().format('MMM DD, YY')
              return (
                <div
                  key={_id}
                  className={`align-items-center d-flex px-2 py-3 m-0 mb-1 cursor-pointer ${!isRead ? 'new-item' : ''}`}
                >
                  <div className="d-flex-1" onClick={this.handleClickItem(notification)}>
                    <p className="dsl-p14 mb-1 truncate-one">{title}</p>
                    <p className="dsl-p12 mb-1 truncate-one">{body}</p>
                    <p className="dsl-b12 mb-0">{createdDate}</p>
                  </div>
                  <div className="d-flex d-h-end px-0">
                    {!isRead && (
                      <EditDropdown options={['Mark As Read']} onChange={this.handleDropdown(notification)} />
                    )}
                  </div>
                </div>
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    )
  }
}

export default Notification
