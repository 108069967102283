import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown } from '@components'
import AppActions from '~/actions/app'
import Product from './Product'
import { find } from 'ramda'

const SORTOPTION = [
  { _id: 1, name: 'Ascending', label: 'asc' },
  { _id: 2, name: 'Descending', label: 'desc' },
]
class MenuList extends Component {
  state = {
    menuItems: [],
    sort: 'desc',
    categoryTitle: '',
  }

  componentDidMount() {
    this.props.getProducts()
    this.props.getCategory()
  }

  handleEdit = (item) => {
    this.props.toggleModal({
      type: 'Edit Product',
      data: { before: { data: item } },
    })
  }

  handleDelete = (item) => {
    this.props.toggleModal({
      type: 'Confirm',
      data: {
        before: {
          title: 'Delete',
          body: 'Would you like to delete this Product?',
        },
      },
      callBack: {
        onYes: () => {
          this.props.deleteProduct('product', item._id)
        },
        onNo: () => {},
      },
    })
  }

  handleDropdown = (e) => {
    this.setState({ categoryTitle: e[0]?.title })
  }

  render() {
    const { menuList } = this.props
    const { sort, categoryTitle } = this.state
    const category = menuList?.map((item, index) => ({ title: item?._id, _id: index + 1 }))
    const menu = find((item) => item._id === categoryTitle, menuList)
    const menuItems = menu?.items

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Menu List</a>
                </li>
              </ol>
            </nav>

            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Product List</h6>
              </div>
              <div className="mt-0 mt-md-4">
                <div className="d-h-between">
                  <Dropdown
                    className="form-control m-2"
                    placeholder="Select Category"
                    title="Menu Category"
                    returnBy="data"
                    data={category}
                    getValue={(e) => e.title}
                    onChange={(e) => this.handleDropdown(e)}
                  />
                  <Dropdown
                    className="form-control m-2"
                    placeholder="Select Order"
                    title="Order By"
                    defaultIds={[2]}
                    returnBy="data"
                    data={SORTOPTION}
                    getValue={(e) => e.name}
                    onChange={(e) => this.setState({ sort: e[0]?.label })}
                  />
                </div>
                <Product data={menuItems} sort={sort} onEdit={this.handleEdit} onDelete={this.handleDelete} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MenuList.propTypes = {
  menuList: PropTypes.object,
}

MenuList.defaultProps = {
  menuList: {},
}

const mapStateToProps = (state) => ({
  menuList: state.app.menuList,
  category: state.app.category,
})

const mapDispatchToProps = (dispatch) => ({
  getProducts: (page) => dispatch(AppActions.getproductRequest(page)),
  getCategory: () => dispatch(AppActions.categoryRequest()),
  deleteProduct: (mode, id) => dispatch(AppActions.deleteRequest(mode, id)),
  toggleModal: (e) => dispatch(AppActions.modalRequest(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuList)
