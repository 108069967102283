import Login from '~/layouts/Login'
import views from '~/views'

const dashboardRoutes = [
  {
    path: '/login',
    name: 'Admin Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Mandala Dashboard',
    component: views,
  },
  {
    redirect: true,
    path: '/',
    to: '/login',
    name: 'Mandala IC Admin',
  },
]

export default dashboardRoutes
