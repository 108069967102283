import React, { Component } from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { connect } from 'react-redux'
import AppActions from '~/actions/app'

class Home extends Component {
  render() {
    return (
      <div className="card d-flex" style={{ height: 'calc(100vh - 80px)', placeContent: 'center' }}>
        <Row className="justify-content-center">
          <div
            className="col-xl-3 col-lg-6 col-md-6 col-sm-6 cursor-pointer hover"
            onClick={() => {
              this.props.history.push('/home/order')
            }}
          >
            <div className="ms-card">
              <div className="ms-card-body py-5">
                <div className="ms-card-heading-title dsl-b23 bold"> Navigate To Order</div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-6 col-md-6 col-sm-6 cursor-pointer hover"
            onClick={() => {
              this.props.history.push('/home/reservation')
            }}
          >
            <div className="ms-card">
              <div className="ms-card-body py-5">
                <div className="ms-card-heading-title dsl-b23 bold">Navigate To Reservation</div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  navbar: () => dispatch(AppActions.navbarRequest()),
  // getApplicantByOpening: (id, route) => dispatch(AppActions.getapplicantbyopeningRequest(id, route)),
  // getApplicantDetail: (applicantId, route) => dispatch(AppActions.getapplicantdetailRequest(applicantId, route)),
})

export default connect(null, mapDispatchToProps)(Home)
