import firebase from 'firebase/app'
import '@firebase/messaging'
import { toast } from 'react-toastify'

const config = {
  apiKey: process.env.FCM_API_KEY,
  authDomain: process.env.FCM_AUTH_DOMAIN,
  projectId: process.env.FCM_PROJECT_ID,
  storageBucket: process.env.FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.FCM_SENDER_ID,
  appId: process.env.FCM_APP_ID,
  measurementId: process.env.FCM_MEASUREMENT_ID,
}

let messaging = null

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config)
  messaging = firebase.messaging()
} else {
  toast.success('Push Notification Not Supported', {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload)
    })
  })

export { messaging }
