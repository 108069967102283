import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loginRequest: ['payload', 'deviceToken'],
  loginSuccess: ['response'],
  loginFailure: null,

  logoutRequest: ['calledFrom'],
  logoutSuccess: null,
  logoutFailure: null,

  // Category
  categoryRequest: ['payload'],
  categorySuccess: ['response'],
  categoryFailure: null,

  addcategoryRequest: ['payload'],
  addcategorySuccess: [null],
  addcategoryFailure: null,

  editcategoryRequest: ['id', 'payload'],
  editcategorySuccess: [null],
  editcategoryFailure: null,

  addproductRequest: ['payload', 'image'],
  addproductSuccess: null,
  addproductFailure: null,

  getproductRequest: ['page'],
  getproductSuccess: ['response'],
  getproductFailure: null,

  getproductdetailRequest: ['id'],
  getproductdetailSuccess: ['response'],
  getproductdetailFailure: null,

  editproductRequest: ['payload', 'id'],
  editproductSuccess: null,
  editproductFailure: null,

  createnewgalleryRequest: ['payload'],
  createnewgallerySuccess: null,
  createnewgalleryFailure: null,

  getgallerylistRequest: ['payload'],
  getgallerylistSuccess: ['response'],
  getgallerylistFailure: null,

  editgalleryRequest: ['payload', 'id'],
  editgallerySuccess: null,
  editgalleryFailure: null,

  getorderlistRequest: ['payload'],
  getorderlistSuccess: ['response'],
  getorderlistFailure: null,

  getorderdetailRequest: ['id', 'route'],
  getorderdetailSuccess: ['response'],
  getorderdetailFailure: null,

  updateorderRequest: ['payload', 'id', 'after'],
  updateorderSuccess: ['response'],
  updateorderFailure: null,

  updateorderpaymentRequest: ['payload', 'id', 'after'],
  updateorderpaymentSuccess: null,
  updateorderpaymentFailure: null,

  // waiting list
  addreservationRequest: ['payload'],
  addreservationSuccess: null,
  addreservationFailure: null,

  // reservation
  createreservationRequest: ['payload'],
  createreservationSuccess: null,
  createreservationFailure: null,

  getbookinglistRequest: ['payload'],
  getbookinglistSuccess: ['response'],
  getbookinglistFailure: null,

  getbookingdetailRequest: ['id', 'route'],
  getbookingdetailSuccess: ['response'],
  getbookingdetailFailure: null,

  // waiting list
  getwaitinglistRequest: ['payload'],
  getwaitinglistSuccess: ['response'],
  getwaitinglistFailure: null,

  getwaitinglistdetailRequest: ['id', 'route'],
  getwaitinglistdetailSuccess: ['response'],
  getwaitinglistdetailFailure: null,

  updatewaitingRequest: ['payload', 'id', 'callBack'],
  updatewaitingSuccess: null,
  updatewaitingFailure: null,

  // reservation
  updatereservationRequest: ['payload', 'id', 'isStatus', 'callBack'],
  updatereservationSuccess: null,
  updatereservationFailure: null,

  deleteRequest: ['mode', 'id', 'tab'],
  deleteSuccess: null,
  deleteFailure: null,

  //Send Sms for Orders
  sendsmsorderRequest: ['id', 'payload', 'mode', 'after', 'callBack'],
  sendsmsorderSuccess: null,
  sendsmsorderFailure: null,

  // Notification

  // Set Notification Token
  setnotificationtokenRequest: ['payload'],
  setnotificationtokenSuccess: null,
  setnotificationtokenFailure: null,

  getallnotificationRequest: ['payload'],
  getallnotificationSuccess: ['response'],
  getallnotificationFailure: null,

  markallasreadRequest: ['payload'],
  markallasreadSuccess: null,
  markallasreadFailure: null,

  markasreadRequest: ['id', 'payload', 'route'],
  markasreadSuccess: null,
  markasreadFailure: null,

  //Settings
  getsettingRequest: null,
  getsettingSuccess: ['response'],
  getsettingFailure: null,

  postsettingRequest: ['mode', 'payload'],
  postsettingSuccess: null,
  postsettingFailure: null,

  postcategorysettingRequest: ['category', 'payload'],
  postcategorysettingSuccess: null,
  postcategorysettingFailure: null,

  navbarRequest: null,

  locationsRequest: ['payload'],
  modalRequest: ['payload'],
  versionRequest: null,

  // Clear all caches
  clearRequest: null,
})

export const AppTypes = Types
export default Creators
