import React, { useEffect } from 'react'
import { Icon } from '@components'
import ding from '~/assets/ding.mp3'
import './Notification.scss'

function Notification({ data, onDetail, onClose }) {
  const audio = new Audio(ding)
  useEffect(() => {
    if (typeof audio.loop == 'boolean') {
      audio.loop = true
    } else {
      audio.addEventListener(
        'ended',
        function () {
          this.currentTime = 0
          this.play()
        },
        false
      )
    }
    audio.play()
  }, [])

  const handleDetailPage = () => {
    const notificationType = data?.data?.type
    let route = null
    switch (notificationType) {
      case 'newOrder':
        route = `/home/order/${data?.data?.orderId}`
        break
      case 'updatedOrder':
        route = `/home/order/${data?.data?.orderId}`
        break
      case 'newReservation':
        route = `/home/reservation/${data?.data?.bookingId}`
        break
      case 'updateReservation':
        route = `/home/reservation/${data?.data?.bookingId}`
        break
      default:
        break
    }
    onDetail(data?.data?.notificationId, null, route)
    onClose()
    audio.pause()
  }

  return (
    <div className="confirmation-modal">
      <div className="modal-header">
        <Icon name="fal fa-info-circle" color="#FF9100" size={20} />
        <span className="dsl-b18 bold pl-2">New Notification</span>
      </div>
      <div className="modal-body d-flex align-items-center px-5">
        <div className="d-flex d-flex-3 align-items-center">
          <span className="dsl-b18 text-500">{data?.notification?.title}</span>
        </div>
        <span className="dsl-b16 text-500 mt-2">{data?.notification?.body}</span>
      </div>
      <div className="d-center mb-4">
        <button
          className="btn btn-primary d-block"
          style={{ fontSize: '20px' }}
          type="submit"
          onClick={handleDetailPage}
        >
          Click Here
        </button>
      </div>
    </div>
  )
}

export default Notification
