import React, { Component } from 'react'
import { Dropdown } from '@components'

class EditProduct extends Component {
  state = {
    productName: this.props.data.productName,
    currency: 'USD',
    quantity: '',
    price: this.props.data.price,
    description: this.props.data.description,
    isAvailable: this.props.data.isAvailable,
    isDiscountable: this.props.data.isDiscountable,
    isTodaysSpecial: this.props.data.isTodaysSpecial,
    categoryId: this.props.data.category[0]?._id,
    images: '',
    image: this.props.data?.images[0]?.url,
  }

  handleDrop = (e) => {
    const file = e.target.files
    this.setState({ images: file[0] })
    if (file.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result })
      })
      reader.readAsDataURL(file[0])
    }
  }

  handleSubmit = () => {
    const {
      productName,
      currency,
      quantity,
      price,
      description,
      isAvailable,
      isDiscountable,
      categoryId,
      image,
      images,
      isTodaysSpecial,
    } = this.state

    const formData = new FormData()
    if (images) {
      formData.set('Content-Type', images.type)
      formData.append('images', images)
      formData.append('folder', 'Mandala')
    }
    formData.append('productName', productName)
    formData.append('currency', currency)
    formData.append('price', price)
    formData.append('description', description)
    formData.append('isAvailable', isAvailable)
    formData.append('isDiscountable', isDiscountable)
    formData.append('isTodaysSpecial', isTodaysSpecial)
    formData.append('category', categoryId)

    this.props.onEdit(formData, this.props.data._id)
    this.props.onClose()
  }

  render() {
    const {
      productName,
      price,
      description,
      isAvailable,
      isDiscountable,
      isTodaysSpecial,
      images,
      image,
      categoryId,
    } = this.state
    const { category } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">Edit Product</h3>
        </div>
        <div className="modal-body">
          <div className="ms-content-wrapper">
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-body">
                    <form className="needs-validation clearfix">
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label>Product Name</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom18"
                              placeholder="Product Name"
                              value={productName}
                              required
                              onChange={(e) => this.setState({ productName: e.target.value })}
                            />
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label>Select Catagory</label>
                          <div className="input-group">
                            <Dropdown
                              className="form-control"
                              placeholder="Select Category"
                              returnBy="data"
                              defaultIds={[categoryId]}
                              data={category?.data || []}
                              getValue={(e) => e.title}
                              onChange={(e) => this.setState({ categoryId: e[0]?._id })}
                            />
                            <div className="invalid-feedback">Please select a Catagory.</div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Price</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom25"
                              placeholder="$10"
                              value={price}
                              required
                              onChange={(e) => this.setState({ price: e.target.value })}
                            />
                            <div className="invalid-feedback">Price</div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label>Description</label>
                          <div className="input-group">
                            <textarea
                              rows="5"
                              id="validationCustom12"
                              className="form-control"
                              placeholder="Description"
                              value={description}
                              required
                              onChange={(e) => this.setState({ description: e.target.value })}
                            ></textarea>
                            <div className="invalid-feedback">Please provide a message.</div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label>Product Image</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              accept="image/*"
                              onChange={this.handleDrop}
                            />
                            <label className="custom-file-label" placeholder="Upload Product Image">
                              {images?.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ms-panel">
                      <div className="ms-panel-header">
                        <h6>Product </h6>
                      </div>
                      {image && (
                        <div className="ms-panel-body">
                          <div id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img className="d-block w-100" src={image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="ms-panel-header new">
                        <p className="medium">Status Available</p>
                        <div>
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              checked={isAvailable}
                              onChange={(e) => this.setState({ isAvailable: e.target.checked })}
                            />
                            <span className="ms-switch-slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="ms-panel-header new">
                        <p className="medium">Discount Active</p>
                        <div>
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              checked={isDiscountable}
                              onChange={(e) => this.setState({ isDiscountable: e.target.checked })}
                            />
                            <span className="ms-switch-slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="ms-panel-header new">
                        <p className="medium">Today's Special</p>
                        <div>
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              checked={isTodaysSpecial}
                              onChange={(e) => this.setState({ isTodaysSpecial: e.target.checked })}
                            />
                            <span className="ms-switch-slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="ms-panel-header new">
                        <button
                          className="btn btn-primary d-block"
                          style={{ fontSize: '18px' }}
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-primary d-block"
                          style={{ fontSize: '18px' }}
                          type="submit"
                          onClick={() => this.props.onClose()}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditProduct
