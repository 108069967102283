import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import AppActions from '~/actions/app'
import logo from '~/assets/img/logo-final.png'

class SideNav extends Component {
  state = {
    active: '',
    width: window.innerWidth,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  handleSideNav = (item) => () => {
    const { sideNav } = this.props
    const { width } = this.state
    const isMobile = width <= 1024

    this.setState({ active: item })
    if (sideNav && item !== 'gallery' && item !== 'menu' && isMobile) this.props.navbar()
  }

  render() {
    const { menu, active, width } = this.state
    const { sideNav, history } = this.props
    const isMobile = width <= 1024

    return (
      <aside
        id="ms-side-nav"
        className={classNames(
          'side-nav fixed ms-aside-scrollable ms-aside-left ps ps--active-y ',
          sideNav && 'ms-aside-open'
        )}
      >
        <div className="ms-d-block-sm" onClick={() => this.props.navbar()}>
          <div className="d-h-end my-3">
            <i className="fal fa-times" style={{ fontSize: '25px' }}></i>
          </div>
        </div>
        <div className="logo-sn">
          <a className="pl-0 ml-0 text-center">
            <img src={logo} width="216" height="62" alt="logo" />
          </a>
        </div>
        <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          <li className="menu-item">
            <a
              className={classNames(
                'has-chevron',
                active === 'gallery' ? '' : 'collasped',
                active === 'gallery' && 'active'
              )}
              aria-expanded={active === 'gallery'}
              onClick={this.handleSideNav('gallery')}
            >
              <span>
                <i className="fa fa-file-image fs-16"></i>Gallery
              </span>
            </a>
            <ul id="product" className={classNames('cursor-pointer', 'collapse', active === 'gallery' && 'show')}>
              <li>
                <a
                  onClick={() => {
                    history.push({ search: '', pathname: '/home/slider/add' })
                    if (sideNav && isMobile) this.props.navbar()
                  }}
                >
                  Add Gallery Image
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    history.push({ search: '', pathname: '/home/slider/list' })
                    if (sideNav && isMobile) this.props.navbar()
                  }}
                >
                  Gallery List
                </a>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <a
              className={classNames(
                'cursor-pointer',
                'has-chevron',
                active === 'menu' ? '' : 'collasped',
                active === 'menu' && 'active'
              )}
              aria-expanded={menu}
              onClick={this.handleSideNav('menu')}
            >
              <span>
                <i className="fa fa-archive fs-16"></i>Menus
              </span>
            </a>
            <ul id="product" className={classNames('cursor-pointer', 'collapse', active === 'menu' && 'show')}>
              <li>
                <a
                  onClick={() => {
                    history.push({ pathname: '/home/menu/list', search: '' })
                    if (sideNav && isMobile) this.props.navbar()
                  }}
                >
                  Menu List
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    history.push({ pathname: '/home/menu/addproduct', search: '' })
                    if (sideNav && isMobile) this.props.navbar()
                  }}
                >
                  Add Product
                </a>
              </li>
            </ul>
          </li>
          <li className={classNames('menu-item')} onClick={this.handleSideNav('order')}>
            <a
              onClick={() => history.push({ pathname: '/home/order', search: '' })}
              className={classNames('cursor-pointer', active === 'order' ? 'active' : '')}
            >
              <span>
                <i className="fas fa-clipboard-list fs-16"></i>Orders
              </span>
            </a>
          </li>
          <li className={classNames('menu-item')} onClick={this.handleSideNav('reservation')}>
            <a
              onClick={() => history.push({ pathname: '/home/reservation', search: '' })}
              className={classNames('cursor-pointer', active === 'reservation' ? 'active' : '')}
            >
              <span>
                <i className="fa fa-tasks fs-16"></i>Reservation
              </span>
            </a>
          </li>
          <li className={classNames('menu-item')} onClick={this.handleSideNav('waiting list')}>
            <a
              onClick={() => history.push({ pathname: '/home/waitingList', search: '' })}
              className={classNames('cursor-pointer', active === 'waiting list' ? 'active' : '')}
            >
              <span>
                <i className="fa fa-tasks fs-16"></i>Waiting List
              </span>
            </a>
          </li>
          <li className={classNames('menu-item')} onClick={this.handleSideNav('category')}>
            <a
              onClick={() => history.push({ pathname: '/home/category', search: '' })}
              className={classNames('cursor-pointer', active === 'category' ? 'active' : '')}
            >
              <span>
                <i className="fa fa-filter fs-16"></i>Category
              </span>
            </a>
          </li>
          <li className={classNames('menu-item')} onClick={this.handleSideNav('setting')}>
            <a
              onClick={() => history.push({ pathname: '/home/settings', search: '' })}
              className={classNames('cursor-pointer', active === 'setting' ? 'active' : '')}
            >
              <span>
                <i className="fa fa-cog fs-16"></i>Settings
              </span>
            </a>
          </li>
        </ul>
      </aside>
    )
  }
}

const mapStateToProps = (state) => ({
  sideNav: state.app.navbar,
})

const mapDispatchToProps = (dispatch) => ({
  navbar: () => dispatch(AppActions.navbarRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)
