import apisauce from 'apisauce'
import { isNil, isEmpty } from 'ramda'
import { store } from '~/reducers'
import AppActions from '~/actions/app'

const Config = {
  API_URL: process.env.API_URL,
}

const authenticated = (api) => {
  api.setHeader('Authorization', window.token)
  return api
}

const naviMonitor = (response) => {
  if (response.status === 401) {
    store.dispatch(AppActions.logoutRequest('Service'))
  }
}

const create = (baseURL = Config.API_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // 50 second timeout...
    timeout: 50000,
  })

  const uploadApi = apisauce.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    // 50 second timeout...
    timeout: 50000,
  })

  api.addMonitor(naviMonitor)
  // Login API
  const adminLogin = (payload) => api.post('admin/login', payload)
  const logOut = () => authenticated(api).post('admin/auth/logout')

  // Category
  const getCategory = (options) => authenticated(api).get(`admin/product/category/list?${options}`)
  const addCategory = (payload) => authenticated(api).post('admin/product/category', payload)
  const editCategory = (id, payload) => authenticated(api).patch(`admin/product/category/${id}`, payload)

  //Products
  const addProduct = (payload) => authenticated(uploadApi).post('admin/product', payload)
  const getProductList = (options) => authenticated(api).get(`admin/product/list?${options}`)
  const getProductDetail = (id) => authenticated(api).get(`admin/product/${id}`)
  const editProduct = (id, payload) => authenticated(uploadApi).patch(`admin/product/${id}`, payload)

  //Gallery

  const createNewGallery = (payload) => authenticated(uploadApi).post('admin/gallery', payload)
  const editGallery = (id, payload) => authenticated(uploadApi).patch(`admin/gallery/${id}`, payload)
  const getGalleryList = (options) => authenticated(api).get(`admin/gallery?${options}`)
  const deleteApi = (id, mode) => {
    if (mode === 'product') {
      return authenticated(api).delete(`admin/product/${id}`)
    } else if (mode === 'gallery') {
      return authenticated(api).delete(`admin/gallery/${id}`)
    } else if (mode === 'waitinglist') {
      return authenticated(api).delete(`admin/booking/${id}`)
    } else if (mode === 'order') {
      return authenticated(api).delete(`admin/order/${id}`)
    } else if (mode === 'reservation') {
      return authenticated(api).delete(`admin/reservation/${id}`)
    }
  }

  // Notifications

  const setNotificationToken = (payload) => authenticated(api).post('admin/notification', payload)
  const getAllNotification = (options) => authenticated(api).get(`admin/notification?${options}`)
  const markAllAsRead = (options) => authenticated(api).get(`admin/notification/mark-all-read?${options}`)
  const markAsRead = (id, options) => authenticated(api).get(`admin/notification/${id}?${options}`)

  //order
  const getOrderList = (options) => authenticated(api).get(`admin/order?${options}`)
  const getOrderDetail = (id) => authenticated(api).get(`admin/order/${id}`)
  const updateOrderStatus = (id, payload) => authenticated(api).patch(`admin/order/${id}`, payload)
  const updateOrderPaymentStatus = (id, payload) => authenticated(api).patch(`admin/order/payment/${id}`, payload)
  const sendSmsOrder = (id, payload, type) => authenticated(api).post(`admin/${type}/sendSms/${id}`, payload)

  //waiting list
  const postWaitingList = (payload) => authenticated(api).post(`admin/booking`, payload)
  const getWaitingList = (options) => authenticated(api).get(`admin/booking?${options}`)
  const getWaitingListDetail = (id) => authenticated(api).get(`admin/booking/${id}`)
  const updateWaiting = (id, payload) => authenticated(api).patch(`admin/booking/${id}`, payload)

  //reservation
  const getBookingList = (options) => authenticated(api).get(`admin/reservation?${options}`)
  const getBookingDetail = (id) => authenticated(api).get(`admin/reservation/${id}`)
  const updateReservationStatus = (id, payload) => authenticated(api).patch(`admin/reservation/${id}`, payload)
  const updateReservation = (id, payload) => authenticated(api).patch(`admin/reservation/update/${id}`, payload)
  const postReservation = (payload) => authenticated(api).post(`admin/reservation`, payload)

  //Settings
  const getStatus = (mode) => authenticated(api).get(`admin/settings/${mode}`)
  const patchStatus = (mode, payload) => authenticated(api).patch(`admin/settings/${mode}`, payload)
  const postCategoryStatus = (payload) => authenticated(api).patch(`admin/settings/productCategory`, payload)

  return {
    adminLogin,
    logOut,
    getCategory,
    addCategory,
    editCategory,
    addProduct,
    getProductList,
    getProductDetail,
    editProduct,
    createNewGallery,
    getGalleryList,
    editGallery,
    deleteApi,
    setNotificationToken,
    getOrderList,
    getOrderDetail,
    updateOrderStatus,
    updateOrderPaymentStatus,
    sendSmsOrder,
    postWaitingList,
    getWaitingList,
    getWaitingListDetail,
    updateWaiting,
    updateReservation,
    updateReservationStatus,
    getAllNotification,
    markAllAsRead,
    markAsRead,
    getBookingList,
    getBookingDetail,
    postReservation,
    getStatus,
    patchStatus,
    postCategoryStatus,
  }
}

export default {
  create,
}
