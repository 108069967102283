import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import AppActions from '~/actions/app'

function Category({ data, onClose, mode }) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState(data?.title)
  const [description, setDescription] = useState(data?.description)
  const [isHidden, setHidden] = useState(data?.isHidden)
  const [categoryPriority, setPriority] = useState(data?.categoryPriority)

  const handleSubmit = () => {
    const payload = {
      title,
      description,
      isHidden,
      status: 1,
      categoryPriority,
    }
    dispatch(AppActions.editcategoryRequest(data?._id, payload))
    onClose()
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title">{mode === 'detail' ? 'Detail' : 'Edit'} Category</h3>
      </div>
      <div className="modal-body">
        <div className="ms-panel-body">
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <label>Title</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  disabled={mode === 'detail'}
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label>Description</label>
              <div className="input-group">
                <textarea
                  rows="5"
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  disabled={mode === 'detail'}
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="medium">Hidden in Menu</label>
              <div>
                <label className="ms-switch">
                  <input
                    disabled={mode === 'detail'}
                    type="checkbox"
                    checked={isHidden}
                    onChange={(e) => setHidden(e.target.checked)}
                  />
                  <span className="ms-switch-slider round"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label>Priority</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Category Priority"
                  disabled={mode === 'detail'}
                  value={categoryPriority}
                  required
                  onChange={(e) => setPriority(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {mode === 'edit' && (
        <div className="modal-footer">
          <button
            className="btn btn-square btn-outline-success"
            style={{ fontSize: '18px' }}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  )
}

export default Category
