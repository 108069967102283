import { any, filter, find, includes, isEmpty, isNil, join, propEq, values } from 'ramda'

const pkg = require('../../package.json')

export const loading = (status, query = 'pending') => {
  return any((e) => includes(query, e))(status)
}

export const version = () => {
  return pkg.version
}

export const removeStatus = (status, from) => {
  return filter((e) => e !== status, from)
}

/**
 * @param {number} index
 * @param {number} page
 * @param {number} per
 */
export const inPage = (index, page, per) => {
  return index >= (page - 1) * per && index < page * per
}

/**
 * @description Get page numbers for pagination.
 * Example Usage: pagination(1, 10)
 * Example Response: 1, 2, 3, ..., 9, 10
 * @param c current page number of the pagination
 * @param m total page counts of the pagination
 * @returns page range numbers with "..."
 */
export const pagination = (c, m) => {
  var currentPage = c,
    last = m,
    delta = 3,
    left = currentPage - delta,
    right = currentPage + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

export const normalizePhoneInput = (value) => {
  if (value) {
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length
    if (cvLength < 4) return currentValue
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }
}

export const denormalizePhoneInput = (value) => {
  if (value) {
    const currentValue = value.replace(/[- )(]/g, '')
    return currentValue.slice(0, 10)
  }
}

export const otherStatus = (value = 1) => {
  if (value === 1) return 'Pending'
  if (value === 2) return 'Accepted'
  if (value === 3) return 'Rejected'
  if (value === 4) return 'Delivered'
}

export const orderStatus = (value = 1) => {
  if (value === 1) return 'Pending'
  if (value === 2) return 'Active'
  if (value === 3) return 'Rejected'
  if (value === 4) return 'Completed'
  if (value === 5) return 'Deleted'
}

export const spiceLevelStatus = (value = 1) => {
  if (value === 1) return 'Mild'
  if (value === 2) return 'Medium'
  if (value === 3) return 'Hot'
}

export const paymentStatus = (value = 1) => {
  if (value === 1) return 'Pending'
  if (value === 2) return 'Completed'
  if (value === 3) return 'Rejected'
}

export const order = (value = 'Pending') => {
  if (value === 'Pending') return 1
  if (value === 'Active') return 2
  if (value === 'Completed') return 4
  if (value === 'Rejected') return 3
  if (value === 'Deleted') return 5
}

export const reverseOrder = (value = 'Pending') => {
  if (value === 1) return 'Pending'
  if (value === 2) return 'Active'
  if (value === 4) return 'Completed'
  if (value === 3) return 'Rejected'
  if (value === 5) return 'Deleted'
}

export const getSettings = (ids, data, empty) => {
  if (isNil(ids) || isNil(data)) return empty
  let items = []
  ids.map((id) => {
    const item = find(propEq('_id', id), data)
    if (item) items.push(item)
  })
  items = items.map((e) => e.title)
  let placeholder = join(', ', items)
  if (isEmpty(placeholder)) placeholder = empty
  return placeholder
}
