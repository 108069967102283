import { call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import { history } from '../reducers'
import AppActions from '../actions/app'
import { isNil, pickBy, values } from 'ramda'
import 'firebase/messaging'

export function* loginRequest(api, action) {
  try {
    const { payload, deviceToken } = action
    const response = yield api.adminLogin(payload)

    if (response.ok) {
      const { accessToken } = response.data.data.tokenData
      window.token = accessToken
      yield put(AppActions.loginSuccess({ accessToken }))
      yield put(AppActions.setnotificationtokenRequest({ deviceToken }))
      yield put(AppActions.categoryRequest())
      yield call(history.push, '/home')
    } else {
      toast.error('Oops, username or password is wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(AppActions.loginFailure())
    }
  } catch (e) {
    yield put(AppActions.loginFailure())
  }
}

export function* logoutRequest(api, action) {
  const { calledFrom } = action
  if (calledFrom === 'Service') {
    yield put(AppActions.clearRequest())
    yield put(AppActions.logoutSuccess())
  } else {
    const response = yield api.logOut()
    yield put(AppActions.clearRequest())
    yield put(AppActions.logoutSuccess())
  }
}

export function* categoryRequest(api, action) {
  try {
    const { payload } = action

    const isNotNull = (val, key) => !isNil(val)
    const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })

    const response = yield api.getCategory(options)
    if (response.ok) {
      yield put(AppActions.categorySuccess(response.data))
    } else {
      yield put(AppActions.categoryFailure())
    }
  } catch (e) {
    yield put(AppActions.categoryFailure())
  }
}
export function* addcategoryRequest(api, action) {
  try {
    const { payload } = action

    const response = yield api.addCategory(payload)
    if (response.ok) {
      yield put(AppActions.addcategorySuccess())
      yield put(AppActions.categoryRequest())
    } else {
      yield put(AppActions.addcategoryFailure())
    }
  } catch (e) {
    yield put(AppActions.addcategoryFailure())
  }
}

export function* editcategoryRequest(api, action) {
  try {
    const { id, payload } = action

    const response = yield api.editCategory(id, payload)
    if (response.ok) {
      yield put(AppActions.editcategorySuccess())
      yield put(AppActions.categoryRequest())
    } else {
      yield put(AppActions.editcategoryFailure())
    }
  } catch (e) {
    yield put(AppActions.editcategoryFailure())
  }
}

export function* addproductRequest(api, action) {
  try {
    const { payload, image } = action
    const formData = new FormData()
    formData.set('Content-Type', image.type)
    formData.append('images', image)
    formData.append('productName', payload.productName)
    formData.append('currency', payload.currency)
    formData.append('quantity', payload.quantity)
    formData.append('price', payload.price)
    formData.append('description', payload.description)
    formData.append('isAvailable', payload.isAvailable)
    formData.append('isTodaysSpecial', payload.isTodaysSpecial)
    formData.append('isDiscountable', payload.isDiscountable)
    formData.append('category', payload.category)

    const response = yield api.addProduct(formData)
    if (response.ok) {
      yield put(AppActions.addproductSuccess())
      toast.success('Product Added Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield call(history.push, '/home/menu/list')
    } else {
      yield put(AppActions.addproductFailure())
      toast.error('There was an error when adding the product', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  } catch (e) {
    yield put(AppActions.addproductFailure())
  }
}

export function* getproductRequest(api, action) {
  try {
    const { page } = action

    const isNotNull = (val, key) => !isNil(val)
    const options = queryString.stringify(pickBy(isNotNull, page), { arrayFormat: 'bracket' })

    const response = yield api.getProductList(options)
    if (response.ok) {
      const { data } = response.data

      yield put(AppActions.getproductSuccess(data))
    } else {
      yield put(AppActions.getproductFailure())
    }
  } catch (e) {
    yield put(AppActions.getproductFailure())
  }
}

export function* getproductdetailRequest(api, action) {
  try {
    const { id } = action

    const response = yield api.getProductDetail(id)
    if (response.ok) {
      const { data } = response.data
      yield put(AppActions.getproductdetailSuccess({ data }))
    } else {
      yield put(AppActions.getproductdetailFailure())
    }
  } catch (e) {
    yield put(AppActions.getproductdetailFailure())
  }
}

export function* createnewgalleryRequest(api, action) {
  try {
    const { payload } = action

    const response = yield api.createNewGallery(payload)
    if (response.ok) {
      yield put(AppActions.createnewgallerySuccess())
      toast.success('Gallery Image Added Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield call(history.push, '/home/slider/list')
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.createnewgalleryFailure())
    }
  } catch (e) {
    yield put(AppActions.createnewgalleryFailure())
  }
}

export function* getgallerylistRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })

  try {
    const response = yield api.getGalleryList(options)
    if (response.ok) {
      const { data, pagination } = response.data
      yield put(AppActions.getgallerylistSuccess({ data, pagination }))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getgallerylistFailure())
    }
  } catch (e) {
    yield put(AppActions.getgallerylistFailure())
  }
}

export function* editgalleryRequest(api, action) {
  try {
    const { payload, id } = action

    const response = yield api.editGallery(id, payload)
    if (response.ok) {
      yield put(AppActions.editgallerySuccess())
      yield put(AppActions.getgallerylistRequest())
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.editgalleryFailure())
    }
  } catch (e) {
    yield put(AppActions.editgalleryFailure())
  }
}

export function* deleteRequest(api, action) {
  try {
    const { mode, id, tab } = action

    const response = yield api.deleteApi(id, mode)
    if (response.ok) {
      yield put(AppActions.deleteSuccess())
      toast.success('Deleted Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      if (mode === 'product') {
        yield put(AppActions.getproductRequest())
      } else if (mode === 'gallery') {
        yield put(AppActions.getgallerylistRequest({ page: 1 }))
      } else if (mode === 'booking') {
        yield put(AppActions.getwaitinglistRequest({ page: 1 }))
      } else if (mode === 'order') {
        yield call(history.push, '/home/order')
      } else if (mode === 'reservation') {
        yield put(AppActions.getbookinglistRequest({ page: 1 }))
      }
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.deleteFailure())
    }
  } catch (e) {
    yield put(AppActions.deleteFailure())
  }
}

export function* editproductRequest(api, action) {
  try {
    const { payload, id } = action

    const response = yield api.editProduct(id, payload)
    if (response.ok) {
      yield put(AppActions.editproductSuccess())
      toast.success('Product Updated Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(AppActions.getproductRequest())
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.editproductFailure())
    }
  } catch (e) {
    yield put(AppActions.editproductFailure())
  }
}

export function* getorderlistRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })
  try {
    const response = yield api.getOrderList(options)
    if (response.ok) {
      const { data, pagination } = response.data

      yield put(AppActions.getorderlistSuccess({ data, pagination }))
    } else {
      yield put(AppActions.getorderlistFailure())
    }
  } catch (e) {
    yield put(AppActions.getorderlistFailure())
  }
}

export function* getorderdetailRequest(api, action) {
  const { id, route } = action

  try {
    const response = yield api.getOrderDetail(id)
    if (response.ok) {
      const { data } = response.data

      yield put(AppActions.getorderdetailSuccess({ data }))
      if (route) yield call(history.push, route)
    } else {
      yield put(AppActions.getorderdetailFailure())
    }
  } catch (e) {
    yield put(AppActions.getorderdetailFailure())
  }
}

export function* updateorderRequest(api, action) {
  const { payload, id, after } = action

  try {
    const response = yield api.updateOrderStatus(id, payload)
    if (response.ok) {
      yield put(AppActions.getorderdetailRequest(id))
      if (after) yield put({ ...after })
      yield put(AppActions.updateorderSuccess())
      if (payload.status === '4') {
        yield call(history.push, '/home/order')
      }
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.updateorderFailure())
    }
  } catch (e) {
    yield put(AppActions.updateorderFailure())
  }
}

export function* updateorderpaymentRequest(api, action) {
  const { payload, id, after } = action

  try {
    const response = yield api.updateOrderPaymentStatus(id, payload)
    if (response.ok) {
      yield put(AppActions.getorderdetailRequest(id))
      yield put(AppActions.updateorderpaymentSuccess())
      if (after) yield put({ ...after })
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.updateorderpaymentFailure())
    }
  } catch (e) {
    yield put(AppActions.updateorderpaymentFailure())
  }
}

export function* setnotificationtokenRequest(api, action) {
  const { payload } = action
  try {
    const response = yield api.setNotificationToken(payload)

    if (response.ok) {
      yield put(AppActions.setnotificationtokenSuccess())
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.setnotificationtokenFailure())
    }
  } catch (e) {
    yield put(AppActions.setnotificationtokenFailure())
  }
}
// add waiting list
export function* addreservationRequest(api, action) {
  try {
    const { payload } = action

    const response = yield api.postWaitingList(payload)
    if (response.ok) {
      yield put(AppActions.addreservationSuccess())
      toast.success('Added Waiting List', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(
        AppActions.getwaitinglistRequest({
          page: 1,
          type: 2,
          itemsPerPage: 10,
          sortBy: ['bookingTime'],
          sortDesc: ['false'],
        })
      )
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.addreservationFailure())
    }
  } catch (e) {
    yield put(AppActions.addreservationFailure())
  }
}

// add reservations from admin
export function* createreservationRequest(api, action) {
  try {
    const { payload } = action

    const response = yield api.postReservation(payload)
    if (response.ok) {
      yield put(AppActions.createreservationSuccess())
      toast.success('Added Reservations', {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(
        AppActions.getbookinglistRequest({
          page: 1,
          type: 1,
          itemsPerPage: 10,
          sortBy: ['bookingTime'],
          sortDesc: ['false'],
        })
      )
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.createreservationFailure())
    }
  } catch (e) {
    yield put(AppActions.createreservationFailure())
  }
}

export function* markasreadRequest(api, action) {
  const { id, payload, route } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })

  try {
    const response = yield api.markAsRead(id, options)
    if (response.ok) {
      yield put(AppActions.markasreadSuccess())
      yield put(AppActions.getallnotificationRequest(payload))
      if (route) yield call(history.push, route)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.markasreadFailure())
    }
  } catch (e) {
    yield put(AppActions.markasreadFailure())
  }
}

export function* getbookinglistRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })
  try {
    const response = yield api.getBookingList(options)
    if (response.ok) {
      const { data, pagination } = response.data

      yield put(AppActions.getbookinglistSuccess({ data, pagination }))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getbookinglistFailure())
    }
  } catch (e) {
    yield put(AppActions.getbookinglistFailure())
  }
}

export function* getbookingdetailRequest(api, action) {
  const { id, route } = action

  try {
    const response = yield api.getBookingDetail(id)
    if (response.ok) {
      const { data } = response.data

      yield put(AppActions.getbookingdetailSuccess({ data }))
      if (route) yield call(history.push, route)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getbookingdetailFailure())
    }
  } catch (e) {
    yield put(AppActions.getbookingdetailFailure())
  }
}

export function* getwaitinglistRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })
  try {
    const response = yield api.getWaitingList(options)
    if (response.ok) {
      const { data, pagination } = response.data

      yield put(AppActions.getwaitinglistSuccess({ data, pagination }))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getwaitinglistFailure())
    }
  } catch (e) {
    yield put(AppActions.getwaitinglistFailure())
  }
}

export function* getwaitinglistdetailRequest(api, action) {
  const { id, route } = action

  try {
    const response = yield api.getWaitingListDetail(id)
    if (response.ok) {
      const { data } = response.data

      yield put(AppActions.getwaitinglistdetailSuccess({ data }))
      if (route) yield call(history.push, route)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getwaitinglistdetailFailure())
    }
  } catch (e) {
    yield put(AppActions.getwaitinglistdetailFailure())
  }
}

export function* updatereservationRequest(api, action) {
  const { payload, id, isStatus, callBack } = action
  try {
    const response = isStatus
      ? yield api.updateReservationStatus(id, payload)
      : yield api.updateReservation(id, payload)
    if (response.ok) {
      yield put(AppActions.updatereservationSuccess())
      yield put(AppActions.getbookingdetailRequest(id))
      if (callBack) yield callBack()
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.updatereservationFailure())
    }
  } catch (e) {
    yield put(AppActions.updatereservationFailure())
  }
}

export function* updatewaitingRequest(api, action) {
  const { payload, id, callBack } = action

  try {
    const response = yield api.updateWaiting(id, payload)
    if (response.ok) {
      yield put(AppActions.updatewaitingSuccess())
      yield put(AppActions.getwaitinglistdetailRequest(id))
      if (callBack) yield callBack()
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.updatewaitingFailure())
    }
  } catch (e) {
    yield put(AppActions.updatewaitingFailure())
  }
}

export function* sendsmsorderRequest(api, action) {
  const { id, payload, mode, after, callBack } = action

  try {
    const response = yield api.sendSmsOrder(id, payload, mode)
    if (response.ok) {
      if (mode === 'order') {
        yield put(AppActions.getorderdetailRequest(id))
      } else {
        yield put(AppActions.getbookingdetailRequest(id))
      }
      if (callBack) yield callBack()
      yield put(AppActions.sendsmsorderSuccess())
      toast.success('SMS has be sent', {
        position: toast.POSITION.TOP_RIGHT,
      })
      if (after) yield put({ ...after })
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.sendsmsorderFailure())
    }
  } catch (e) {
    yield put(AppActions.sendsmsorderFailure())
  }
}

export function* getallnotificationRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })

  try {
    const response = yield api.getAllNotification(options)
    if (response.ok) {
      const { data, pagination, badge } = response.data
      yield put(AppActions.getallnotificationSuccess({ data, pagination, badge }))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getallnotificationFailure())
    }
  } catch (e) {
    yield put(AppActions.getallnotificationFailure())
  }
}

export function* markallasreadRequest(api, action) {
  const { payload } = action

  const isNotNull = (val, key) => !isNil(val)
  const options = queryString.stringify(pickBy(isNotNull, payload), { arrayFormat: 'bracket' })

  try {
    const response = yield api.markAllAsRead(options)
    if (response.ok) {
      yield put(AppActions.markallasreadSuccess())
      yield put(AppActions.getallnotificationRequest(payload))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getgallerylistFailure())
    }
  } catch (e) {
    yield put(AppActions.getgallerylistFailure())
  }
}

export function* getsettingRequest(api, action) {
  try {
    const response1 = yield api.getStatus('onlineOrder')
    const response2 = yield api.getStatus('onlineReservation')
    const response3 = yield api.getStatus('taxRate')
    if (response1.ok && response2.ok && response3.ok) {
      const data = {
        orderStatus: response1.data.data,
        reservationStatus: response2.data.data,
        taxRate: response3.data.data,
      }
      yield put(AppActions.getsettingSuccess({ data }))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.getsettingFailure())
    }
  } catch (e) {
    yield put(AppActions.getsettingFailure())
  }
}

export function* postsettingRequest(api, action) {
  const { mode, payload } = action

  try {
    const response = yield api.patchStatus(mode, payload)
    if (response.ok) {
      yield put(AppActions.postsettingSuccess())
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
      })
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.postsettingFailure())
    }
  } catch (e) {
    yield put(AppActions.postsettingFailure())
  }
}

export function* postcategorysettingRequest(api, action) {
  const { category, payload } = action

  try {
    const response = yield api.postCategoryStatus(payload)

    if (response.ok) {
      yield put(AppActions.postcategorysettingSuccess())
      toast.success(payload.categoryIds?.length === 0 ? category : `${category} are hidded for users`, {
        position: toast.POSITION.TOP_CENTER,
      })
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      })
      yield put(AppActions.postcategorysettingFailure())
    }
  } catch (e) {
    yield put(AppActions.postcategorysettingFailure())
  }
}
