import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from '@components'
import AppActions from '~/actions/app'

const Error = ({ text }) => <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'red' }}>{text}</span>

class AddProduct extends Component {
  state = {
    productName: '',
    currency: 'USD',
    quantity: '',
    price: '',
    description: '',
    isAvailable: true,
    isDiscountable: false,
    isTodaysSpecial: false,
    categoryId: '',
    images: '',
    image: '',
    nameError: '',
    priceError: '',
    categoryError: '',
  }

  componentDidMount() {
    this.props.getCategory()
  }

  handleDrop = (e) => {
    const file = e.target.files
    this.setState({ images: file[0] })
    if (file.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result })
      })
      reader.readAsDataURL(file[0])
    }
  }

  handleSubmit = () => {
    const {
      productName,
      currency,
      quantity,
      price,
      description,
      isAvailable,
      isDiscountable,
      isTodaysSpecial,
      categoryId,
      images,
      image,
    } = this.state
    if (!productName) {
      this.setState({ nameError: 'Product Name Is Required' })
    }
    if (!price) {
      this.setState({ priceError: 'Price is Required' })
    }
    if (!categoryId) {
      this.setState({ categoryError: 'Category is Required' })
    }
    if (productName && price && categoryId) {
      const payload = {
        productName,
        currency: 'USD',
        quantity,
        price,
        description,
        isAvailable,
        isDiscountable,
        isTodaysSpecial,
        category: categoryId,
      }
      this.props.addProduct(payload, images)
    }
  }

  render() {
    const {
      productName,
      price,
      description,
      isAvailable,
      isDiscountable,
      isTodaysSpecial,
      images,
      image,
      nameError,
      priceError,
      categoryError,
    } = this.state
    const { category } = this.props
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red">Menu</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Add Product</a>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div className="ms-panel-header">
                <h6>Add Product Form</h6>
              </div>
              <div className="ms-panel-body">
                <form className="needs-validation clearfix">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>Product Name</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          placeholder="Product Name"
                          value={productName}
                          required
                          onChange={(e) => this.setState({ productName: e.target.value, nameError: '' })}
                        />
                      </div>
                      <Error text={nameError}></Error>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label>Select Catagory</label>
                      <div className="input-group">
                        <Dropdown
                          className="form-control"
                          placeholder="Select Category"
                          returnBy="data"
                          data={category?.data || []}
                          getValue={(e) => e.title}
                          onChange={(e) => this.setState({ categoryId: e[0]?._id, categoryError: '' })}
                        />
                        <Error text={categoryError}></Error>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>Price</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom25"
                          placeholder="$10"
                          value={price}
                          required
                          onChange={(e) => this.setState({ price: e.target.value, priceError: '' })}
                        />
                      </div>
                      <Error text={priceError}></Error>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Description</label>
                      <div className="input-group">
                        <textarea
                          rows="5"
                          id="validationCustom12"
                          className="form-control"
                          placeholder="Description"
                          value={description}
                          required
                          onChange={(e) => this.setState({ description: e.target.value })}
                        ></textarea>
                        <div className="invalid-feedback">Please provide a message.</div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label>Product Image</label>
                      <div className="custom-file">
                        <input type="file" className="custom-file-input" accept="image/*" onChange={this.handleDrop} />
                        <label className="custom-file-label" placeholder="Upload Product Image">
                          {images.name}
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Product </h6>
                  </div>
                  {image && (
                    <div className="ms-panel-body">
                      <div id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img className="d-block w-100" src={image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="ms-panel-header new">
                    <p className="medium">Status Available</p>
                    <div>
                      <label className="ms-switch">
                        <input
                          type="checkbox"
                          checked={isAvailable}
                          onChange={(e) => this.setState({ isAvailable: e.target.checked })}
                        />
                        <span className="ms-switch-slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="ms-panel-header new">
                    <p className="medium">Discount Active</p>
                    <div>
                      <label className="ms-switch">
                        <input
                          type="checkbox"
                          checked={isDiscountable}
                          onChange={(e) => this.setState({ isDiscountable: e.target.checked })}
                        />
                        <span className="ms-switch-slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="ms-panel-header new">
                    <p className="medium">Today's Special</p>
                    <div>
                      <label className="ms-switch">
                        <input
                          type="checkbox"
                          checked={isTodaysSpecial}
                          onChange={(e) => this.setState({ isTodaysSpecial: e.target.checked })}
                        />
                        <span className="ms-switch-slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="ms-panel-header new">
                    <button className="btn btn-primary d-block" type="submit" onClick={this.handleSubmit}>
                      Save and Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  category: state.app.category,
})

const mapDispatchToProps = (dispatch) => ({
  getCategory: () => dispatch(AppActions.categoryRequest()),
  addProduct: (payload, image) => dispatch(AppActions.addproductRequest(payload, image)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct)
