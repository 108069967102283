import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose, filter, includes, prop, propEq, sortBy, toLower } from 'ramda'
import { Dropdown, EditDropdown, Pagination, Thumbnail } from '@components'
import AppActions from '~/actions/app'
import { history } from '~/reducers'

class SliderList extends Component {
  state = {
    image: '',
  }

  componentDidMount() {
    this.props.getGalleryList({ page: 1 })
  }

  handleDetail = (item) => () => {
    this.setState({ image: item.images[0].url })
  }

  handleMenu = (e, item) => {
    if (e === 'edit') {
      this.props.toggleModal({
        type: 'Edit Slider',
        data: { before: { data: item } },
      })
    }
    if (e === 'delete') {
      this.props.deleteGallery('gallery', item._id)
      this.setState({ image: '' })
    }
  }

  handlePagination = (page) => {
    this.props.getGalleryList({ page })
  }

  render() {
    const { slider } = this.props
    const { data, pagination } = slider
    const { image } = this.state

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red">Gallery Image</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Gallery List</a>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh card">
              <div className="d-h-between">
                <h6>Gallery</h6>
                <div className="d-flex align-items-baseline">
                  <button
                    type="button"
                    className="btn btn-square btn-link"
                    onClick={() => this.props.history.push('/home/slider/add')}
                  >
                    Add Gallery Image
                  </button>
                </div>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <div className="list-header">
                    <div className="d-flex-2">Thumbnail</div>
                    <div className="d-flex d-flex-1">Used in Slider</div>
                    <div className="d-flex d-flex-1">Priority</div>
                    <div className="d-flex d-flex-1" />
                  </div>
                  {data?.map((item) => (
                    <div className="list-item" key={item._id} onClick={this.handleDetail(item)}>
                      <div className="d-flex-2">
                        <Thumbnail src={item.images[0].url} />
                      </div>
                      <div className="d-flex-1">{item.isFeaturedImage ? 'Yes' : 'No'}</div>
                      <div className="d-flex-1">{item.galleryPriority}</div>

                      <div className="d-flex d-flex-1 d-h-end">
                        <EditDropdown onChange={(e) => this.handleMenu(e, item)} />
                      </div>
                    </div>
                  ))}
                  <Pagination
                    current={pagination?.currentPage}
                    total={pagination?.totalPage}
                    per={pagination?.limit}
                    pers={[]}
                    onChange={this.handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
          {image && (
            <div className="col-xl-6 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-body">
                      <div id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img className="d-block w-100" src={image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

SliderList.propTypes = {
  slider: PropTypes.shape({
    data: PropTypes.array,
    pagination: PropTypes.object,
  }),
}

SliderList.defaultProps = {
  slider: {
    data: [],
    pagination: {},
  },
}

const mapStateToProps = (state) => ({
  slider: state.app.slider,
})

const mapDispatchToProps = (dispatch) => ({
  getGalleryList: (page) => dispatch(AppActions.getgallerylistRequest(page)),
  deleteGallery: (mode, id) => dispatch(AppActions.deleteRequest(mode, id)),
  toggleModal: (e) => dispatch(AppActions.modalRequest(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SliderList)
