import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from '@components'

import AppActions from '~/actions/app'
import moment from 'moment'
import OrderDetailPDF from './OrderPdf'
import { normalizePhoneInput, orderStatus, paymentStatus, spiceLevelStatus } from '~/services/utils'

export class OrderDetail extends Component {
  state = {
    id: this.props.match.params.id,
    kitchen: false,
  }

  componentDidMount() {
    this.props.getOrder(this.state.id)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.id) {
      const id = nextProps.match.params.id
      nextProps.getOrder(id)
      return { id }
    } else return null
  }

  handleSms = (mode) => () => {
    const { id } = this.state

    if (mode === 'orderAcceptedSms') {
      this.props.sendsmsorderRequest(id, { slug: 'orderAcceptedSms', reason: null, time: null }, 'order', null)
    }
    if (mode === 'orderRejectedSms') {
      this.props.toggleModal({
        type: 'Send SMS',
        data: { before: { id, slug: 'orderRejectedSms' } },
      })
    }
  }

  hanldeStatus = (mode) => () => {
    const { id } = this.state
    const { order } = this.props

    let payload = {}
    if (mode === 'accept') {
      payload = {
        status: '2',
      }
    }
    if (mode === 'reject') {
      payload = {
        status: '3',
      }
    }
    this.props.updateStatus(payload, id)
  }

  handleSendSms = () => {
    const { id } = this.state
    this.props.sendsmsorderRequest(id, { slug: 'orderReadySms', reason: null, time: null }, 'order', null)
  }

  handleOrderReady = () => {
    const { id } = this.state

    const payload = {
      status: '4',
    }
    this.props.updateStatus(payload, id)
  }

  handlePayment = (mode) => () => {
    const { id } = this.state
    let payload = {}
    if (mode === 'accept') {
      payload = {
        paymentStatus: '2',
      }
    }
    if (mode === 'reject') {
      payload = {
        paymentStatus: '3',
      }
    }
    this.props.updatePaymentStatus(payload, id)
  }

  handlePdf = () => {
    const { id } = this.state
    const { order } = this.props
    OrderDetailPDF({ id, order })
    this.setState({ kitchen: true })
  }

  handleDelete = () => {
    const { id } = this.state
    this.props.deleteOrder('order', id)
  }

  handleBack = () => {
    this.props.toggleModal({
      type: 'Confirm',
      data: {
        before: {
          title: 'Confirmation',
          body: 'Did you send the order to the kitchen?',
        },
      },
      callBack: {
        onYes: () => {
          this.props.history.push('/home/order')
        },
        onNo: () => {},
      },
    })
  }

  render() {
    const { id } = this.state
    const { order } = this.props

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push({ pathname: '/home', search: '' })}>
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red cursor-pointer" onClick={() => this.handleBack()}>
                    Order
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>{id}</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="d-h-between">
            <h4>Order Details</h4>
          </div>
          <div className="d-center">
            <div className="cursor-pointer" onClick={this.handlePdf}>
              <Icon name="far fa-print" color="#ff0018" size={100} />
              <div className="dsl-b16 bold text-red">Send To Kitchen</div>
            </div>
          </div>
          <div className="d-h-end">
            {order?.aggregateStatus != '5' && (
              <button
                type="button"
                className="btn btn-square btn-outline-danger"
                style={{ fontSize: '16px' }}
                onClick={this.handleDelete}
              >
                Delete Order
              </button>
            )}
          </div>
          <div className="ms-panel-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="dsl-b18 my-2 bold">
                  Order Code: <span className="dsl-b18 text-400">{order?.orderCode}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Name: <span className="dsl-b18 text-400">{order?.basicInfo?.name}</span>
                </div>
                {order?.basicInfo?.contactNumber && (
                  <div className="dsl-b18 my-2 bold">
                    Phone No.:{' '}
                    <span className="dsl-b18 text-400">{`${order?.basicInfo?.phoneCode}${normalizePhoneInput(
                      order?.basicInfo?.contactNumber
                    )}`}</span>
                  </div>
                )}
                {order?.basicInfo?.email && (
                  <div className="dsl-b18 my-2 bold">
                    Email: <span className="dsl-b18 text-400">{order?.basicInfo?.email}</span>
                  </div>
                )}
                <div className="dsl-b18 my-2 bold">
                  Pick Up By: <span className="dsl-b18 text-400">{order?.pickupInfo?.pickUpBy}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Pick Up Time:{' '}
                  <span className="dsl-b18 text-400">
                    {moment(new Date(order?.pickupInfo?.pickupTime)).utc(false).format(' MMM Do, h:mm a')}
                  </span>
                </div>
                {order?.pickupNow && (
                  <div className="dsl-b18 my-2 bold">
                    PickupNow: <span className="dsl-b18 text-400">{order?.basicInfo?.email}</span>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 text-right ssm-left">
                {order?.aggregateStatus === 2 &&
                  order?.orderAcceptedRejectedSms === 2 &&
                  order?.orderReadySms === 1 &&
                  order?.receiveSms && (
                    <div>
                      <button
                        type="button"
                        style={{ fontSize: '16px' }}
                        className="btn btn-square btn-outline-success"
                        onClick={this.handleSendSms}
                      >
                        Send Order Ready Text
                      </button>
                    </div>
                  )}
                {order?.aggregateStatus >= 2 &&
                  order?.aggregateStatus !== 3 &&
                  order?.aggregateStatus !== 4 &&
                  order.paymentStatus === 2 && (
                    <div>
                      <button
                        type="button"
                        style={{ fontSize: '16px' }}
                        className="btn btn-square btn-outline-success"
                        onClick={this.handleOrderReady}
                      >
                        Change Order Status To Delivered
                      </button>
                    </div>
                  )}
                <div className="dsl-b18 my-2 bold">
                  <span className="dsl-b18 text-400">{order?.receiveSms ? 'Receiving SMS' : 'Not Receiving SMS'}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Payment Type:{' '}
                  <span className="dsl-b18 text-400">{order?.paymentType === 2 ? 'Card Payment' : 'Pay At Store'}</span>
                </div>
                {order?.aggregateStatus === 2 && order?.orderAcceptedRejectedSms === 1 && order?.receiveSms && (
                  <button
                    type="button"
                    style={{ fontSize: '16px' }}
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('orderAcceptedSms')}
                  >
                    Send Order Accepted Sms
                  </button>
                )}
                {order?.aggregateStatus === 3 && order?.orderAcceptedRejectedSms === 1 && order?.receiveSms && (
                  <button
                    type="button"
                    style={{ fontSize: '16px' }}
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('orderRejectedSms')}
                  >
                    Reject SMS
                  </button>
                )}
                {order?.aggregateStatus === 1 && (
                  <>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-success"
                      onClick={this.hanldeStatus('accept')}
                    >
                      Accept Order
                    </button>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-danger ml-2"
                      onClick={this.hanldeStatus('reject')}
                    >
                      Decline Order
                    </button>
                  </>
                )}
                {order?.aggregateStatus !== 1 && (
                  <div className="dsl-b18 my-2 bold">
                    Order Status:
                    <span className="dsl-b18 text-400">{orderStatus(order?.aggregateStatus)}</span>
                  </div>
                )}
                <div className="dsl-b18 my-2 bold">
                  Payment Status:
                  <span className="dsl-b18 text-400"> {paymentStatus(order?.paymentStatus)}</span>
                </div>
                {order?.paymentType === 1 && order?.paymentStatus === 1 && order?.aggregateStatus !== 1 && (
                  <>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-success"
                      onClick={this.handlePayment('accept')}
                    >
                      Accept Payment
                    </button>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-danger ml-2"
                      onClick={this.handlePayment('reject')}
                    >
                      Decline Payment
                    </button>
                  </>
                )}
              </div>
            </div>
            {order?.productArray?.map((item) => (
              <div
                className="d-flex align-items-start d-h-between py-3 bg-slight-grey border-5 px-2 my-2"
                key={item.productId}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex-1">
                    <Icon name="fal fa-utensils-alt mr-1 color-basic" color="#343f4b" size={16} />
                    <span className="dsl-b18 bold">{item.productName}</span>
                  </div>
                  <div className="d-flex-1">
                    <Icon name="fal fa-pepper-hot mr-1 color-basic" color="#343f4b" size={16} />
                    <span className=" dsl-b18 text-400">{spiceLevelStatus(item?.spiceLevel)}</span>
                  </div>
                  <div className="d-flex-1 dsl-b18 text-400">
                    <Icon name="fal fa-tally mr-1 color-basic" color="#343f4b" size={16} />
                    <span className=" dsl-b18 text-400">{item.quantity}</span>
                  </div>
                  {item?.specialInstruction && (
                    <div className="d-flex-2 dsl-b18">
                      Special Instruction: <span className="d-flex-2 dsl-b18 text-400">{item?.specialInstruction}</span>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column">
                  <span className=" dsl-b18 text-400">${Number(item.price).toFixed(2)}</span>
                </div>
              </div>
            ))}
            <div className="d-h-end pt-2">
              <div className="d-flex-2" />
              <div className="d-h-between d-flex-1 dsl-b18 text-500 text-right mr-1">
                <div>Sub Total:</div>
                <div>${Number(order?.subTotal).toFixed(2)}</div>
              </div>
            </div>
            <div className="d-h-end pt-2">
              <div className="d-flex-2" />
              <div className="d-h-between d-flex-1 dsl-b18 text-500 text-right mr-1">
                <div>Tax:</div>
                <div>${Number(order?.tax).toFixed(2)}</div>
              </div>
            </div>
            <div className="d-h-end pt-2">
              <div className="d-flex-2" />
              <div className="d-h-between d-flex-1 dsl-b18 text-500 text-right mr-1">
                <div>Tips:</div>
                <div>${order?.tips}</div>
              </div>
            </div>
            <div className="d-h-end pt-2">
              <div className="d-flex-2" />
              <div className="d-h-between d-flex-1 dsl-b18 text-500 text-right mr-1">
                <div>Total:</div>
                <div>${Number(order?.totalPrice).toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  order: state.app.orderItem,
})

const mapDispatchToProps = (dispatch) => ({
  getOrder: (id) => dispatch(AppActions.getorderdetailRequest(id)),
  deleteOrder: (mode, id) => dispatch(AppActions.deleteRequest(mode, id)),
  updateStatus: (payload, id) => dispatch(AppActions.updateorderRequest(payload, id)),
  updatePaymentStatus: (payload, id) => dispatch(AppActions.updateorderpaymentRequest(payload, id)),
  sendsmsorderRequest: (id, payload, mode, after) => dispatch(AppActions.sendsmsorderRequest(id, payload, mode, after)),
  toggleModal: (e) => dispatch(AppActions.modalRequest(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail)
