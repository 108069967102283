import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import { equals, isEmpty } from 'ramda'
import AppActions from '~/actions/app'
import viewRoutes from '~/routes/views'

class Views extends Component {
  render() {
    const { authenticated } = this.props

    return (
      <Switch>
        {viewRoutes.map((prop, key) => {
          if (prop.redirect && authenticated) {
            return <Redirect exact from={prop.path} to={prop.to} key={key} />
          }
          if (prop.redirect && !authenticated) {
            return <Route exact path={prop.path} component={prop.component} key={key} />
          }
          if (!prop.redirect && prop.auth && !authenticated) {
            return <Redirect exact from={prop.path} to="/" key={key} />
          }
          return <Route exact path={prop.path} component={prop.component} key={key} />
        })}
      </Switch>
    )
  }
}

Views.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  history: PropTypes.any,
}

Views.defaultProps = {
  authenticated: false,
  history: () => {},
}

const mapStateToProps = (state) => ({
  role: state.app.app_role_id,
  authenticated: !isEmpty(state.app.token),
})

export default connect(mapStateToProps, null)(Views)
