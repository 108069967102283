import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppActions from '~/actions/app'
import moment from 'moment'
import { normalizePhoneInput, otherStatus } from '~/services/utils'

class ReservationDetail extends Component {
  state = {
    id: this.props.match.params.id,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.id) {
      const id = nextProps.match.params.id
      nextProps.getReservation(id)
      return { id }
    } else return null
  }

  componentDidMount() {
    this.props.getReservation(this.state.id)
  }

  handleSms = (mode) => () => {
    const { id } = this.state
    if (mode === 'reservationRejectedSms') {
      this.props.sendsmsorderRequest(
        id,
        { slug: 'reservationRejectedSms', reason: null, time: null },
        'reservation',
        null
      )
    }
    if (mode === 'reservationAcceptedSms') {
      this.props.sendsmsorderRequest(
        id,
        { slug: 'reservationAcceptedSms', reason: null, time: null },
        'reservation',
        null
      )
    }
    if (mode === 'reservationReadySms') {
      this.props.sendsmsorderRequest(id, { slug: 'reservationReadySms', reason: null, time: null }, 'reservation', null)
    }
    if (mode === 'tableAlmostReadySms') {
      this.props.sendsmsorderRequest(id, { slug: 'tableAlmostReadySms', reason: null, time: null }, 'reservation', null)
    }
  }

  hanldeStatus = (mode) => () => {
    const { id } = this.state
    const { booking } = this.props

    let payload = {}
    if (mode === 'accept') {
      payload = {
        ...booking,
        status: 2,
      }
    }
    if (mode === 'reject') {
      payload = {
        ...booking,
        status: 3,
      }
    }
    this.props.updateStatus(payload, id)
  }

  render() {
    const { id } = this.state
    const { booking } = this.props

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push('/home/reservation')}>
                    Reservation
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>{id}</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <h4>Reservation Details</h4>
          <div className="ms-panel-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="dsl-b18 my-2 bold">
                  Name: <span className="dsl-b18 text-400">{booking?.fullName}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Phone No.:{' '}
                  <span className="dsl-b18 text-400">
                    {booking?.phoneCode}
                    {normalizePhoneInput(booking?.contactNumber)}
                  </span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Arrival Time:{' '}
                  <span className="dsl-b18 text-400">
                    {moment(new Date(booking?.arrivalTime)).utc(false).format(' MMM Do, h:mm a')}
                  </span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Booking Time:{' '}
                  <span className="dsl-b18 text-400">
                    {moment(new Date(booking?.bookingTime)).utc(false).format(' MMM Do, h:mm a')}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 text-right ssm-left">
                <div className="dsl-b18 my-2 bold">
                  Attendees:
                  <span className="dsl-b18 text-400"> {booking?.attendees} </span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  {booking?.isOutdoorReservation ? 'Outdoor Sitting' : 'Indoor Sitting'}
                </div>
                <div className="dsl-b18 my-2 bold">
                  Reservation Status: <span className="dsl-b18 text-400">{otherStatus(booking?.status)}</span>
                </div>
                {booking?.status === 1 && (
                  <>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-success"
                      onClick={this.hanldeStatus('accept')}
                    >
                      Accept
                    </button>
                    <button
                      style={{ fontSize: '16px' }}
                      type="button"
                      className="btn btn-square btn-outline-danger ml-2"
                      onClick={this.hanldeStatus('reject')}
                    >
                      Decline
                    </button>
                  </>
                )}
                {booking?.status === 2 && !booking?.sentAcceptedSms && (
                  <button
                    style={{ fontSize: '16px' }}
                    type="button"
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('reservationAcceptedSms')}
                  >
                    Booking Accepted Sms
                  </button>
                )}
                {booking?.status === 3 && !booking?.sentAcceptedSms && (
                  <button
                    style={{ fontSize: '16px' }}
                    type="button"
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('reservationRejectedSms')}
                  >
                    Booking Rejected Sms
                  </button>
                )}
                {booking?.status === 2 && booking?.sentAcceptedSms && !booking?.sentReadySms && (
                  <button
                    style={{ fontSize: '16px' }}
                    type="button"
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('reservationReadySms')}
                  >
                    Table Ready Sms
                  </button>
                )}
                {booking?.status === 2 && booking?.sentAcceptedSms && !booking?.tableAlmostReadySms && (
                  <button
                    style={{ fontSize: '16px' }}
                    type="button"
                    className="btn btn-square btn-outline-success"
                    onClick={this.handleSms('tableAlmostReadySms')}
                  >
                    Table Almost Ready SMS
                  </button>
                )}
              </div>
            </div>
            {booking?.notes && (
              <div className="d-flex flex-column">
                <div className="dsl-b18 my-2 bold">Notes:</div>
                <div className="dsl-b14 text-400">{booking?.notes}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  booking: state.app.booking,
})

const mapDispatchToProps = (dispatch) => ({
  getReservation: (id) => dispatch(AppActions.getbookingdetailRequest(id)),
  updateStatus: (payload, id) => dispatch(AppActions.updatereservationRequest(payload, id, true, null)),
  sendsmsorderRequest: (id, payload, mode, after) => dispatch(AppActions.sendsmsorderRequest(id, payload, mode, after)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetail)
