import AddProduct from '~/views/AddProduct'
import Home from '~/views/Home'
import MenuList from '~/views/MenuList'
import OrderList from '~/views/Order'
import OrderDetail from '~/views/OrderDetail'
import ReservationDetail from '~/views/ReservationDetail'
import ReservationList from '~/views/ReservationList'
import SliderImage from '~/views/SliderImage'
import SliderList from '~/views/SliderList'
import WaitingListDetail from '~/views/WaitingListDetail'
import WaitingList from '~/views/WaitingList'
import Settings from '~/views/Settings'
import Category from '~/views/Category'

const viewRoutes = [
  {
    path: '/home/dashboard',
    auth: true,
    title: 'Dashboard',
    name: 'mandala',
    component: Home,
  },
  {
    path: '/home/menu/addproduct',
    auth: true,
    title: 'Add Product',
    name: 'add_product',
    component: AddProduct,
  },
  {
    path: '/home/menu/list',
    auth: true,
    title: 'Menu',
    name: 'menu_list',
    component: MenuList,
  },
  {
    path: '/home/slider/add',
    auth: true,
    title: 'Slider Image',
    name: 'slider_image',
    component: SliderImage,
  },
  {
    path: '/home/slider/list',
    auth: true,
    title: 'Slider List',
    name: 'slider_list',
    component: SliderList,
  },
  {
    path: '/home/order',
    auth: true,
    title: 'Order List',
    name: 'order_list',
    component: OrderList,
  },
  {
    path: '/home/order/:id',
    auth: true,
    title: 'Order Detail',
    name: 'order_detail',
    component: OrderDetail,
  },
  {
    path: '/home/reservation',
    auth: true,
    title: 'Reservation List',
    name: 'reservationrder_list',
    component: ReservationList,
  },
  {
    path: '/home/reservation/:id',
    auth: true,
    title: 'Reservation Detail',
    name: 'reservation_detail',
    component: ReservationDetail,
  },
  {
    path: '/home/waitingList',
    auth: true,
    title: 'Waiting List',
    name: 'waiting_list',
    component: WaitingList,
  },
  {
    path: '/home/waitingList/:id',
    auth: true,
    title: 'Waiting List Detail',
    name: 'waiting_detail',
    component: WaitingListDetail,
  },
  {
    path: '/home/settings',
    auth: true,
    title: 'Settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/home/category',
    auth: true,
    title: 'Category',
    name: 'categories',
    component: Category,
  },
  {
    redirect: true,
    path: '/',
    to: '/home',
    name: 'Mandala IC',
    component: Home,
  },
  {
    redirect: true,
    path: '/home',
    to: '/home/dashboard',
    name: 'Mandala IC',
    component: Home,
  },
]

export default viewRoutes
