import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AppActions from '~/actions/app'
import moment from 'moment'
import { normalizePhoneInput, otherStatus } from '~/services/utils'

class WaitingListDetail extends Component {
  state = {
    id: this.props.match.params.id,
  }

  componentDidMount() {
    this.props.getWaitingList(this.state.id)
  }

  hanldeStatus = (mode) => () => {
    const { id } = this.state
    const { reservation } = this.props

    let payload = {}
    if (mode === 'accept') {
      payload = {
        ...reservation,
        status: 2,
      }
    }
    if (mode === 'reject') {
      payload = {
        ...reservation,
        status: 3,
      }
    }
    this.props.updateStatus(payload, id)
  }

  render() {
    const { id } = this.state
    const { reservation } = this.props

    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push('/home/waitingList')}>
                    Waiting
                  </a>{' '}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>{id}</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <h4>Waiting List Details</h4>
          <div className="ms-panel-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="dsl-b18 my-2 bold">
                  Name: <span className="dsl-b18 text-400">{reservation?.fullName}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Phone No.:{' '}
                  <span className="dsl-b18 text-400">
                    {reservation?.phoneCode}
                    {normalizePhoneInput(reservation?.contactNumber)}
                  </span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Arrival Time:{' '}
                  <span className="dsl-b18 text-400">
                    {moment(new Date(reservation?.arrivalTime)).utc(false).format(' MMM Do, h:mm a')}
                  </span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  Booking Time:{' '}
                  <span className="dsl-b18 text-400">
                    {moment(new Date(reservation?.bookingTime)).utc(false).format(' MMM Do, h:mm a')}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 text-right ssm-left">
                <div className="dsl-b18 my-2 bold">
                  Attendees: <span className="dsl-b18 text-400">{reservation?.attendees}</span>
                </div>
                <div className="dsl-b18 my-2 bold">
                  {reservation?.isOutdoorBooking ? 'Outdoor Sitting' : 'Indoor Sitting'}
                </div>
                {/* {reservation?.status === 1 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-square btn-outline-success"
                      onClick={this.hanldeStatus('accept')}
                    >
                      Accept
                    </button>
                    <button
                      type="button"
                      className="btn btn-square btn-outline-danger ml-2"
                      onClick={this.hanldeStatus('reject')}
                    >
                      Decline
                    </button>
                  </>
                )} */}
                {reservation?.status !== 1 && (
                  <div className="dsl-b18 my-2 bold">
                    Waiting List Status: <span className="dsl-b18 text-400">{otherStatus(reservation?.status)}</span>
                  </div>
                )}
              </div>
            </div>
            {reservation?.notes && (
              <div className="d-flex flex-column">
                <div className="dsl-b18 my-2 bold">Notes:</div>
                <div className="dsl-b14 text-400">{reservation?.notes}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reservation: state.app.reservation,
})

const mapDispatchToProps = (dispatch) => ({
  getWaitingList: (id) => dispatch(AppActions.getwaitinglistdetailRequest(id)),
  updateStatus: (payload, id) => dispatch(AppActions.updatebookingRequest(payload, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WaitingListDetail)
