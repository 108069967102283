import React, { Component } from 'react'

class EditSlider extends Component {
  state = {
    title: this.props.data.title,
    image: this.props.data.images[0].url,
    imageTitle: this.props.data.title,
    galleryPriority: this.props.data.galleryPriority,
    images: '',
    isFeaturedImage: this.props.data.isFeaturedImage,
  }

  handleDrop = (e) => {
    const file = e.target.files
    this.setState({ images: file[0], imageTitle: file[0].name })
    if (file.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({ image: reader.result })
      })
      reader.readAsDataURL(file[0])
    }
  }

  handleSubmit = () => {
    const { data, onClose, onEdit } = this.props
    const { title, images, isFeaturedImage, galleryPriority } = this.state

    const formData = new FormData()
    if (images) {
      formData.set('Content-Type', images.type)
      formData.append('images', images)
      formData.append('folder', 'Mandala')
    }
    formData.append('title', title)
    formData.append('isFeaturedImage', isFeaturedImage)
    formData.append('galleryPriority', galleryPriority)

    onEdit(formData, data._id)

    onClose()
  }

  render() {
    const { title, image, imageTitle, isFeaturedImage, galleryPriority } = this.state
    const { onClose } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">Edit Gallery</h3>
        </div>
        <div className="modal-body">
          <div className="ms-panel-body">
            <form className="needs-validation clearfix">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Title</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="Title"
                      value={title}
                      required
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label>Priority</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="validationCustom18"
                      placeholder="galleryPriority"
                      value={galleryPriority}
                      required
                      onChange={(e) => this.setState({ galleryPriority: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <p className="medium">Use in homepage slider?</p>
                  <div>
                    <label className="ms-switch">
                      <input
                        type="checkbox"
                        checked={isFeaturedImage}
                        onChange={(e) => this.setState({ isFeaturedImage: e.target.checked })}
                      />
                      <span className="ms-switch-slider round"></span>
                    </label>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label>Gallery Image</label>
                  {image && (
                    <div className="ms-panel-body">
                      <div id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img className="d-block w-100" src={image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="custom-file">
                    <input type="file" className="custom-file-input" accept="image/*" onChange={this.handleDrop} />
                    <label className="custom-file-label" placeholder="Upload Gallery Image">
                      {imageTitle}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-light" style={{ fontSize: '18px' }} onClick={onClose}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary shadow-none"
            style={{ fontSize: '18px' }}
            onClick={this.handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    )
  }
}

export default EditSlider
