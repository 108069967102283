import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Dropdown, EditDropdown, Icon, Pagination } from '@components'
import AppActions from '~/actions/app'
import { order, orderStatus as _orderStatus, paymentStatus, reverseOrder } from '~/services/utils'
import moment from 'moment'
import './Order.scss'

const _options = [
  { _id: 1, name: 'Name', value: 'name' },
  { _id: 2, name: 'Order Status', value: 'aggregateStatus' },
  { _id: 3, name: 'Payment Status', value: 'paymentStatus' },
  { _id: 4, name: 'Order Placed', value: 'createdAt' },
]

const _order = [
  { _id: 1, name: 'Pending', value: 1 },
  { _id: 2, name: 'Active', value: 2 },
  { _id: 4, name: 'Completed', value: 4 },
  { _id: 3, name: 'Rejected', value: 3 },
  { _id: 5, name: 'Deleted', value: 5 },
]

class OrderList extends Component {
  state = {
    search: '',
    page: 1,
    itemsPerPage: 10,
    sortBy: 'createdAt',
    orderStatus: order(queryString.parse(this.props.location.search)?.filter) || 1,
  }
  componentDidMount() {
    const { page, itemsPerPage, sortBy, orderStatus } = this.state
    this.props.getOrders({ page: 1, itemsPerPage: 10, sortBy: ['createdAt'], sortDesc: ['false'], orderStatus })
  }

  handleDetail = (item) => {
    const route = `/home/order/${item._id}`
    this.props.getOrderDetail(item._id, route)
  }

  handlePagination = (page) => {
    const { sortBy, itemsPerPage, orderStatus } = this.state
    this.props.getOrders({ page, sortBy: [sortBy], itemsPerPage, sortDesc: ['false'], orderStatus })
    this.setState({ page })
  }

  debouncedLog = _.debounce((text) => {
    const { itemsPerPage, sortBy } = this.state
    this.props.getOrders({ page: 1, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], search: text })
  }, 500)

  handleSearch = (e) => {
    this.setState({ search: e })
    this.debouncedLog(e)
  }

  handlePer = (itemsPerPage) => {
    const { sortBy, orderStatus } = this.state
    this.props.getOrders({ page: 1, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus })
    this.setState({ itemsPerPage })
  }

  // handleSort = (e) => {
  //   const { page, itemsPerPage } = this.state
  //   const sortBy = e[0]?.value
  //   this.props.getOrders({ page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'] })
  //   this.setState({ sortBy })
  // }

  hanldeFilter = (e) => {
    const { page, itemsPerPage, sortBy } = this.state
    const orderStatus = e[0]?.value
    this.props.getOrders({ page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus })
    this.setState({ orderStatus })
    this.props.history.push({ search: `?filter=${e[0].name}` })
  }

  handleSms = (mode, id) => {
    const { page, itemsPerPage, sortBy, orderStatus } = this.state
    const after = {
      type: 'GETORDERLIST_REQUEST',
      payload: { page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus },
    }
    if (mode === 'orderAcceptedSms') {
      this.props.sendsmsorderRequest(id, { slug: 'orderAcceptedSms', reason: null, time: null }, 'order', after)
    }
    if (mode === 'orderRejectedSms') {
      this.props.toggleModal({
        type: 'Send SMS',
        data: { before: { id, slug: 'orderRejectedSms', after } },
      })
    }
  }

  hanldeStatus = (mode, id) => {
    const { page, itemsPerPage, sortBy, orderStatus } = this.state
    const after = {
      type: 'GETORDERLIST_REQUEST',
      payload: { page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus },
    }
    let payload = {}
    if (mode === 'accept') {
      payload = {
        status: '2',
      }
    }
    if (mode === 'reject') {
      payload = {
        status: '3',
      }
    }
    if (mode === 'delivery') {
      payload = {
        status: '4',
      }
    }
    this.props.updateStatus(payload, id, after)
  }

  handlePayment = (mode, id) => {
    const { page, itemsPerPage, sortBy, orderStatus } = this.state
    const after = {
      type: 'GETORDERLIST_REQUEST',
      payload: { page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus },
    }
    let payload = {}
    if (mode === 'accept') {
      payload = {
        paymentStatus: '2',
      }
    }
    if (mode === 'reject') {
      payload = {
        paymentStatus: '3',
      }
    }
    this.props.updatePaymentStatus(payload, id, after)
  }

  handleSendSms = (id) => {
    const { page, itemsPerPage, sortBy, orderStatus } = this.state
    const after = {
      type: 'GETORDERLIST_REQUEST',
      payload: { page, itemsPerPage, sortBy: [sortBy], sortDesc: ['false'], orderStatus },
    }
    this.props.sendsmsorderRequest(id, { slug: 'orderReadySms', reason: null, time: null }, 'order', after)
  }

  handleMenu = (event, item) => {
    const id = item._id

    switch (event) {
      case 'view':
        this.handleDetail(item)
        break
      case 'send order accepted sms':
        this.handleSms('orderAcceptedSms', item._id)
        break
      case 'order reject sms':
        this.handleSms('orderRejectedSms', item._id)
        break
      case 'order ready sms':
        this.handleSendSms(item._id)
        break
      case 'accept':
        this.hanldeStatus('accept', item._id)
        break
      case 'reject':
        this.hanldeStatus('reject', item._id)
        break
      case 'order picked up':
        this.hanldeStatus('delivery', item._id)
        break
      default:
        break
    }
  }

  render() {
    const { orders } = this.props
    const { data, pagination } = orders
    const { search, orderStatus } = this.state
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item">
                  <a className="text-red" onClick={() => this.props.history.push({ pathname: '/home', search: '' })}>
                    <i className="material-icons">home</i> Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/home/order">Order</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a>Order List</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="d-h-between">
            <h4>Order List</h4>
            <div className="dataTables_filter">
              <label>
                <input
                  value={search}
                  type="search"
                  className="form-control border-5"
                  placeholder="Search ..."
                  onChange={(e) => this.handleSearch(e.target.value)}
                ></input>
              </label>
            </div>
          </div>
          <div className="d-h-end">
            {/* <Dropdown
              className="form-control"
              title="Sort By"
              placeholder="Sort By"
              returnBy="data"
              defaultIds={[4]}
              data={_options}
              getValue={(e) => e.name}
              onChange={this.handleSort}
            /> */}
            <Dropdown
              className="form-control border-5"
              title="Filter By"
              placeholder="Order Status"
              returnBy="data"
              defaultIds={[orderStatus]}
              data={_order}
              getValue={(e) => e.name}
              onChange={this.hanldeFilter}
            />
          </div>
          {data?.map((item, index) => {
            let options = ['View']
            if (item?.aggregateStatus === 2 && item?.orderAcceptedRejectedSms === 1 && item?.receiveSms)
              options = ['View', 'Send Order Accepted Sms']
            if (item?.aggregateStatus === 3 && item?.orderAcceptedRejectedSms === 1 && item?.receiveSms)
              options = ['View', 'Order Reject Sms']
            if (
              item?.aggregateStatus === 2 &&
              item?.orderAcceptedRejectedSms === 2 &&
              item?.orderReadySms === 1 &&
              item?.receiveSms
            )
              options = ['View', 'Order Ready Sms']
            if (item?.aggregateStatus === 1) options = ['View', 'Accept', 'Reject']
            if (
              item?.aggregateStatus > 3 &&
              item?.aggregateStatus !== 3 &&
              item?.aggregateStatus !== 4 &&
              item.paymentStatus === 2
            )
              options = ['View', 'Order Picked Up']
            return (
              <div
                className="d-flex align-items-start d-h-between py-3 bg-slight-grey border-5 px-2 m-2"
                key={item._id}
                onClick={() => this.handleDetail(item)}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex-1">
                    <span className="dsl-b20 bold">#Order {item?.orderCode}</span>
                  </div>
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-600 main-blue">
                      {item?.basicInfo?.name}
                      <samp></samp>
                    </span>
                  </div>
                  <br />
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-500">${Number(item?.totalPrice).toPrecision(4)}</span>
                  </div>
                  <div className="d-flex-1"></div>
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-500">Order Status: {_orderStatus(item?.aggregateStatus)}</span>
                  </div>
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-500">Payment Status: {paymentStatus(item?.paymentStatus)}</span>
                  </div>
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-500">
                      <Icon name="fal fa-calendar-alt mr-1 color-basic" color="#343f4b" size={16} />
                      {moment(item?.createdAt).utc(true).format(' MMM Do, h:mm a')}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex-1">
                    <span className="dsl-b20 text-500 color-basic">
                      {item?.paymentType === 2 ? 'Pre-Paid' : 'Pay At Store'}
                    </span>
                    {item?.receiveSms && <Icon name="fal fa-comment-alt ml-1 color-basic" color="#343f4b" size={16} />}
                  </div>
                  <EditDropdown options={options} icon="fal fa-ellipsis-h" onChange={(e) => this.handleMenu(e, item)} />
                </div>
              </div>
            )
          })}
          <div className="mt-2">
            <Pagination
              current={pagination?.currentPage}
              total={pagination?.totalPage}
              per={pagination?.limit}
              pers={[10, 20, 50, 'all']}
              onPer={this.handlePer}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </div>
    )
  }
}

OrderList.propTypes = {
  orders: PropTypes.shape({
    data: PropTypes.array,
    pagination: PropTypes.object,
  }),
}

OrderList.defaultProps = {
  orders: {
    data: [],
    pagination: {},
  },
}

const mapStateToProps = (state) => ({
  orders: state.app.order,
})

const mapDispatchToProps = (dispatch) => ({
  getOrders: (page) => dispatch(AppActions.getorderlistRequest(page)),
  getOrderDetail: (id, route) => dispatch(AppActions.getorderdetailRequest(id, route)),
  updateStatus: (payload, id, after) => dispatch(AppActions.updateorderRequest(payload, id, after)),
  updatePaymentStatus: (payload, id, after) => dispatch(AppActions.updateorderpaymentRequest(payload, id, after)),
  sendsmsorderRequest: (id, payload, mode, after) => dispatch(AppActions.sendsmsorderRequest(id, payload, mode, after)),
  toggleModal: (e) => dispatch(AppActions.modalRequest(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
