import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { equals, find, includes, isEmpty, isNil, length } from 'ramda'

import AddNewBooking from './AddBooking'
import EditBooking from './EditBooking'
import EditReservation from './EditReservation'
import AddCategory from './AddCategory'
import EditCategory from './EditCategory'
import EditSlider from './EditSlider'
import EditProduct from './EditProduct'
import Notification from './Notification'
import SendSms from './SendSms'
import AddNewReservation from './AddReservation'
import Confirm from './Confirm'

import AppActions from '~/actions/app'
import './Modals.scss'

class AppModal extends Component {
  constructor(props) {
    super(props)
    this.state = { isChanged: false }
    // Dismiss modals when reloading...
    if (equals(window.performance.navigation.type, 1)) {
      this.hideModal()
    }
  }

  hideModal = () => {
    let after = null

    this.props.toggleModal({
      type: '',
      data: { before: null, after },
      callBack: null,
    })
  }

  render() {
    const { show, type, data, callback } = this.props
    const MODALS = []
    if (isNil(type)) return null
    if (includes('Edit Slider', type)) {
      MODALS.push(
        <Modal className="app-modal large" key="dsm-01" show={show} onHide={this.hideModal}>
          <EditSlider
            data={data.before.data}
            onEdit={(e, id) => this.props.editGallery(e, id)}
            onClose={this.hideModal}
          />
        </Modal>
      )
    }
    if (includes('Edit Product', type)) {
      MODALS.push(
        <Modal className="app-modal large" key="dsm-02" show={show} onHide={this.hideModal}>
          <EditProduct
            data={data.before.data}
            category={this.props.category}
            onEdit={(e, id) => this.props.editProduct(e, id)}
            onClose={this.hideModal}
          />
        </Modal>
      )
    }
    if (includes('Notification', type)) {
      MODALS.push(
        <Modal
          className="app-modal small"
          key="dsm-03"
          backdropClassName="backdrop-custom"
          show={show}
          backdrop="static"
          centered
          onHide={this.hideModal}
        >
          <Notification
            data={data.before.data}
            onDetail={(id, payload, route) => this.props.markAsRead(id, payload, route)}
            onClose={this.hideModal}
          />
        </Modal>
      )
    }
    if (includes('Add New Booking', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-04" show={show} onHide={this.hideModal}>
          <AddNewBooking onAdd={(e) => this.props.addBooking(e)} onClose={this.hideModal} />
        </Modal>
      )
    }
    if (includes('Edit Booking', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-10" show={show} onHide={this.hideModal}>
          <EditBooking
            data={data.before.data}
            onEdit={(id, payload) => this.props.editBooking(id, payload, callback)}
            onClose={this.hideModal}
          />
        </Modal>
      )
    }
    if (includes('Send SMS', type)) {
      MODALS.push(
        <Modal className="app-modal small" key="dsm-05" show={show} onHide={this.hideModal}>
          <SendSms id={data.before.id} slug={data.before.slug} after={data.before.after} onClose={this.hideModal} />
        </Modal>
      )
    }
    if (includes('Add New Reservation', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-06" show={show} onHide={this.hideModal}>
          <AddNewReservation onAdd={(e) => this.props.addReservation(e)} onClose={this.hideModal} />
        </Modal>
      )
    }
    if (includes('Edit Reservation', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-11" show={show} onHide={this.hideModal}>
          <EditReservation
            data={data.before.data}
            onEdit={(id, payload) => this.props.editReservation(id, payload, callback)}
            onClose={this.hideModal}
          />
        </Modal>
      )
    }
    if (includes('Add Category', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-07" show={show} onHide={this.hideModal}>
          <AddCategory onClose={this.hideModal} />
        </Modal>
      )
    }
    if (includes('Edit Category', type)) {
      MODALS.push(
        <Modal className="app-modal medium" key="dsm-08" show={show} onHide={this.hideModal}>
          <EditCategory data={data.before.data} mode={data.before.mode} onClose={this.hideModal} />
        </Modal>
      )
    }
    if (includes('Confirm', type)) {
      MODALS.push(
        <Modal className="app-modal confirm-modal" key="dsm-09" backdrop="static" show={show} onHide={this.hideModal}>
          <Confirm
            title={data.before.__title || data.before.title}
            body={data.before.__body || data.before.body}
            info={data.before.__description || data.before.description}
            yes={data.before.yesButtonText}
            no={data.before.noButtonText}
            onYes={() => {
              callback?.onYes()
              this.hideModal()
            }}
            onNo={() => {
              callback?.onNo()
              this.hideModal()
            }}
          />
        </Modal>
      )
    }

    if (length(MODALS)) return MODALS
    return null
  }
}

AppModal.propTypes = {
  type: PropTypes.string,
}

AppModal.defaultProps = {
  type: '',
}

const mapStateToProps = (state) => ({
  show: !isEmpty(state.app.modalType),
  type: state.app.modalType,
  data: state.app.modalData,
  callback: state.app.modalCallBack,
  category: state.app.category,

  locations: state.app.locations,
})

const mapDispatchToProps = (dispatch) => ({
  markAsRead: (id, e, route) => dispatch(AppActions.markasreadRequest(id, e, route)),
  editGallery: (payload, id) => dispatch(AppActions.editgalleryRequest(payload, id)),
  editProduct: (payload, id) => dispatch(AppActions.editproductRequest(payload, id)),
  toggleModal: (payload) => dispatch(AppActions.modalRequest(payload)),
  getOrderDetail: (id, route) => dispatch(AppActions.getorderdetailRequest(id, route)),
  addBooking: (payload) => dispatch(AppActions.addreservationRequest(payload)),
  addReservation: (payload) => dispatch(AppActions.createreservationRequest(payload)),
  editBooking: (id, payload, callBack) => dispatch(AppActions.updatewaitingRequest(payload, id, callBack)),
  editReservation: (id, payload, callBack) =>
    dispatch(AppActions.updatereservationRequest(payload, id, false, callBack)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppModal)
