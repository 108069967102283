import React, { Component } from 'react'
import Select from 'react-select'
import { Dropdown, CheckBox } from '@components'
import DatePicker from 'react-datepicker'
import { setHours, setMinutes } from 'date-fns'
import moment from 'moment'
import { country } from '~/services/mockups'
import './Reservation.scss'
import { denormalizePhoneInput, normalizePhoneInput } from '~/services/utils'

class AddNewReservation extends Component {
  state = {
    phoneCode: '+1',
    countryCode: 'US',
    contactNumber: '',
    email: '',
    fullName: '',
    attendees: 1,
    type: 1,
    notes: '',
    bookingTime: moment().format('YYYY-MM-DD HH:mm'),
    arrivalTime: null,
    isOutdoorReservation: false,
  }

  handleSubmit = () => {
    const {
      fullName,
      attendees,
      email,
      type,
      bookingTime,
      arrivalTime,
      contactNumber,
      phoneCode,
      countryCode,
      notes,
      isOutdoorReservation,
    } = this.state
    const { onAdd, onClose } = this.props
    if (!fullName || !contactNumber) return null

    const payload = {
      fullName,
      attendees,
      email,
      type: 1,
      bookingTime,
      arrivalTime: moment(arrivalTime).format('YYYY-MM-DD HH:mm'),
      contactNumber: denormalizePhoneInput(contactNumber),
      phoneCode,
      countryCode,
      notes,
      isOutdoorReservation,
      status: '2',
    }
    onAdd(payload)
    onClose()
  }

  render() {
    const {
      fullName,
      attendees,
      email,
      type,
      bookingTime,
      arrivalTime,
      contactNumber,
      phoneCode,
      countryCode,
      notes,
      isOutdoorReservation,
    } = this.state
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">Add New Reservation</h3>
        </div>
        <div className="modal-body">
          <div className="ms-panel-body">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>Full Name</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    value={fullName}
                    required
                    onChange={(e) => this.setState({ fullName: e.target.value })}
                  />
                </div>
              </div>
              {/* <div className="col-md-12 mb-3">
                <label>Email</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    value={email}
                    required
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </div> */}
              {/* <div className="col-md-6 mb-3">
                <label>Country</label>
                <div className="input-group">
                  <Select
                    classNamePrefix="select"
                    className="basic-single w-100"
                    options={country}
                    isSearchable
                    isClearable
                    onChange={(e) => this.setState({ phoneCode: e.dial_code, countryCode: e.code })}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Country Code</label>
                <input disabled name="code" type="text" className="form-control" value={phoneCode} />
              </div> */}
              <div className="col-md-12 mb-3">
                <label>Contact Info</label>
                <div className="input-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Contact Number"
                    value={normalizePhoneInput(contactNumber)}
                    required
                    onChange={(e) => this.setState({ contactNumber: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label>Arrival Time</label>
                <div className="input-group">
                  <div className="w-100 date-picker-booking">
                    <DatePicker
                      className="form-control w-100"
                      selected={arrivalTime}
                      onChange={(arrivalTime) => this.setState({ arrivalTime })}
                      placeholderText="Click to Select The Arrival Time"
                      showTimeSelect
                      fixedHeight
                      timeIntervals={15}
                      minDate={new Date()}
                      minTime={setHours(setMinutes(new Date(), 30), 11)}
                      maxTime={setHours(setMinutes(new Date(), 30), 21)}
                      dateFormat="MMMM d, yyyy hh:mm aa"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label>Attendens</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Attendens"
                    value={attendees}
                    required
                    min="1"
                    max="50"
                    onChange={(e) => {
                      const re = /^[0-9]+$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        this.setState({
                          attendees: e.target.value < 0 ? 1 : e.target.value > 50 ? 50 : e.target.value,
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <CheckBox
                  size="regular"
                  id={'Credit_Card'}
                  checked={isOutdoorReservation}
                  title="Outdoor Sitting"
                  onChange={(e) => this.setState({ isOutdoorReservation: true })}
                />
              </div>
              <div className="col-md-6 mb-3">
                <CheckBox
                  size="regular"
                  id={'cash'}
                  checked={!isOutdoorReservation}
                  title="Indoor Sitting"
                  onChange={(e) => this.setState({ isOutdoorReservation: false })}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label>Additional Notes</label>
                <div className="input-group">
                  <textarea
                    rows="5"
                    className="form-control"
                    placeholder="Additional Notes"
                    value={notes}
                    required
                    onChange={(e) => this.setState({ notes: e.target.value })}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-square btn-outline-success"
            style={{ fontSize: '18px' }}
            type="submit"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    )
  }
}

export default AddNewReservation
