import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Loading } from './components'
import AppActions from './actions/app'
import indexRoutes from './routes'
import { loading, version } from './services/utils'
import { messaging } from './firebase'

const ROOT = (props) => {
  // const dispatch = useDispatch()
  // const aversion = useSelector((state) => state.app.version)
  // useEffect(() => {
  //   if (aversion !== '1.0.0' && aversion !== version()) {
  //     dispatch(AppActions.clearRequest())
  //     window.location.reload(true)
  //     dispatch(AppActions.versionRequest())
  //   }
  // }, [])
  return (
    <>
      {indexRoutes.map((prop, key) => {
        if (prop.name === 'Home') {
          return <Route to={prop.path} component={prop.component} key={key} />
        } else return <Route exact path={prop.path} component={prop.component} key={key} />
      })}
      <Loading loading={props.busy} />
    </>
  )
}

const mapStateToProps = (state) => ({
  busy: loading(state.app.status),
  version: state.app.version,
  token: state.app.token,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AppActions.logoutRequest()),
  setToken: (payload) => dispatch(AppActions.setnotificationtokenRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ROOT)
