import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import AppActions from '~/actions/app'

import image from '~/assets/img/default-login.jpg'
import { messaging } from '~/firebase'

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  // componentDidMount() {
  //   if (this.props.token) {
  //   }
  // }

  async componentDidMount() {
    if (!this.props.token) {
      messaging
        ?.requestPermission()
        .then(async function () {
          const deviceToken = await messaging?.getToken({
            vapidKey: process.env.FCM_VAPID,
          })
          window.localStorage.setItem('deviceToken', deviceToken)
        })
        .catch(function (err) {
          console.log('Unable to get permission to notify.', err)
        })
      navigator.serviceWorker.addEventListener('message', (message) => console.log(message))
    } else {
      this.props.history.push('/home/dashboard')
    }
  }

  handleEnterEvent = (e) => {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { email, password } = this.state
    const deviceToken = window.localStorage.getItem('deviceToken')
    const payload = {
      email,
      password,
    }
    this.props.login(payload, deviceToken)
  }
  render() {
    return (
      <div className="ms-content-wrapper ms-auth">
        <div className="ms-auth-container">
          <div className="ms-auth-col">
            <div className="ms-auth-bg" style={{ backgroundImage: `url(${image})` }} />
          </div>
          <div className="ms-auth-col">
            <div className="ms-auth-form">
              <div className="needs-validation">
                <h3>Login to Account</h3>
                <p>Please enter your email and password to continue</p>
                <div className="mb-3">
                  <label>Email Address</label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <div className="invalid-feedback">Please provide a valid email.</div>
                  </div>
                </div>
                <div className="mb-2">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => this.setState({ password: e.target.value })}
                      onKeyPress={(e) => this.handleEnterEvent(e)}
                    />
                    <div className="invalid-feedback">Please provide a password.</div>
                  </div>
                </div>
                <button className="btn btn-primary mt-4 d-block w-100" onClick={() => this.handleSubmit()}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
})

const mapDispatchToProps = (dispatch) => ({
  login: (payload, deviceToken) => dispatch(AppActions.loginRequest(payload, deviceToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
